import React, { Component } from 'react';
import { redirect, Link, useLocation, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage,  Props as HeaderProps } from '../../components/page/HeaderPage';

import { makeRequest } from '../../helper/request';
import { login } from '../../state/action/user';
import { state } from '../../state/reducer/user';


export interface Props extends HeaderProps {
  login: typeof login,
  user: state,
};


export const DashboardBase = (props: Props) => {
  if (props.user.accessToken === undefined) {
    return <Navigate to="/"/>;
  }
  return <Block.Block alternate={true}>
    <Standard.StandardContent>
      <div style={{ padding: 15, margin: 15, marginLeft: 'auto', marginRight: 'auto', flex: 1, maxWidth: 600 }} >
        <div>AUDIOPLAY Admin Panel</div>

        <div><Link to='/admin/report'>Reports</Link></div>
        <div><Link to='/admin/userReport'>User Report</Link></div>
        <div><Link to='/admin/deviceInfo'>Device Info</Link></div>
      </div>
    </Standard.StandardContent>
  </Block.Block>;
}

export const Dashboard = connect((state: any) => ({ user: state.user }), { login })(DashboardBase);