import React, { Component, useState } from 'react';
import { redirect, Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Elements } from '../../components';

import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';
import { makeRequest } from '@audioplay/client';


export interface Props extends HeaderProps {

};

export const DeviceInfo = (props: Props) => {
  const [deviceId, setDeviceId] = useState('');
  const [deviceData, setDeviceData] = useState<string | undefined>(undefined);
  return <HeaderPage>
    <Block.Block alternate={true}>
      <Standard.StandardContent>
      <Form submitHandler={(action, data) => {
          if (action === 'get.info') {

            const id: string = `${data['deviceId'].value}`;
            setDeviceData(undefined);
            setDeviceId(id);
            return makeRequest('admin.analytics.id.read', {
              analyticsName: id,
            })
              .then((res) => {
                if (res.result !== 'success') {
                  
                  return {
                    ...res.errors,
                    'get.info': Object.values(res.errors || {}).join(' '),
                  }
                } else {
                  setDeviceData(res.email);
                }
                return undefined;
              });
          }
          return Promise.resolve({})
        }} template={[
          {
            type: 'section',
            items: [
              {
                type: 'row',
                display: {
                  align: 'centre',
                  style: {
                    paddingBottom: 10
                  }
                },
                items: [
                  {
                    type: 'text',
                    title: 'User ID',
                    id: 'deviceId',
                    value: '',
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            items: [
              {
                type: 'button',
                id: 'get.info',
                value: 'Get Info',
                onClick: 'get.info',
              },
            ]
          },

        ]}
        />
      </Standard.StandardContent>
      {deviceData === undefined? undefined: <div style={{ display: 'flex', justifyContent: 'center' }}>
        User ID: {deviceId}
        <br/>
        {deviceData? `Email: ${deviceData}`: 'Device Not Logged in'}  
      </div>}
    </Block.Block>
  </HeaderPage>;
}
