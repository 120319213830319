import React, { Component, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { redirect, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { Block, Standard } from '../../components/content';
import { Episode } from '../../components/icon/Episode';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

import ep1 from '../../assets/episodes/TheTurners.Episode1.png';
import ep2 from '../../assets/episodes/TheTurners.Episode2.png';
import ep3 from '../../assets/episodes/TheTurners.Episode3.png';
import ep4 from '../../assets/episodes/TheTurners.Episode4.png';

import { state } from '../../state/reducer/user';
import { makeRequest } from '@audioplay/client';

import { useLoader } from '../../components/util/loader'; 
import { Link } from 'react-router-dom';

interface AccountInfo {
  episodes: {
    [key: string]: {
      permanent: boolean,
      expires: number,
    }
  }
}

export interface Props extends HeaderProps {
  user: state,
  objectRoutes: { name: string, path: string }[],
};

export const AccountBase = (props: Props) => {
  const [account, setAccount] = useState<AccountInfo | undefined>(undefined);
  if (props.user.accessToken === undefined) {
    return <Navigate to="/login"/>;
  }
  
  return <HeaderPage>
    <Block.Block>
      <Standard.StandardContent>
      <div style={{ padding: 15, margin: 15, flex: 1 }} >
        <div>Admin Panel</div>
        <div key={'AnimalNames'}><Link to={'/admin/deviceInfo'}>{'Animal Names'}</Link></div>
        {/* <div key={'Analytics Configuration'}><Link to={'/admin/analyticsConfiguration'}>{'Analytics Configuration'}</Link></div> */}
        <div key={'Presenter Pack'}><Link to={'/presenter-pack'}>{'Add Presenter Pack Access'}</Link></div>
        <div key={'Presenter Pack'}><Link to={'/presenter-pack/turners'}>{'Presenter Pack - Turners'}</Link></div>
        <div key={'Presenter Pack'}><Link to={'/presenter-pack/sunrunners'}>{'Presenter Pack - Sun Runners'}</Link></div>
        <div key={'Presenter Pack'}><Link to={'/presenter-pack/bamboobandits'}>{'Presenter Pack - Bamboo Bandits'}</Link></div>
        {/* <div key={'Funnel'}><Link to={'/admin/funnel'}>{'Funnel'}</Link></div> */}
        {[
          ['Users', 'User'],
          ['Festivals', 'Festival'],
          ['Campaigns', 'Campaign'],
          // ['Signup Codes', 'SignupToken'],
        ].map(([title,name]) => {
          const route = props.objectRoutes.find(i => i.name === name);
          return <div key={title}><Link to={route? route.path: '/'}>{title}</Link></div>
        })}
      </div>
      </Standard.StandardContent>
    </Block.Block>
  </HeaderPage>;
}

export const Account = connect((state: any) => ({ user: state.user }), {})(AccountBase)