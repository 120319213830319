import React, { Component, useState, useEffect } from 'react';
import { redirect, Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Elements } from '../../components';

import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';
import { makeRequest } from '@audioplay/client';
import { Spinner } from '../../components/elements/Spinner';


export interface Props extends HeaderProps {

};

export const AnalyticsConfiguration = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [blacklist, setBlacklist] = useState<{ emails: string[], domains: string[] }>({ emails: [], domains: [] });
  useEffect(() => {
    makeRequest('admin.analytics.blacklist.read', {})
      .then((res) => {
        console.log(res);
        if (res.result === 'success') {
          setBlacklist({
            emails: res.emails || [],
            domains: res.domains || [],
          })
        }
        setLoading(false);
      })
  }, [])

  return <HeaderPage>
    <Block.Block alternate={true}>
      <Standard.StandardContent>
        {loading? <Spinner></Spinner>:
      <Form submitHandler={(action, data) => {
          if (action === 'update') {
            const emails: { [key: string]: boolean } = {};
            const domains: { [key: string]: boolean } = {};
            
            return makeRequest('admin.analytics.blacklist.update', {
              emails,
              domains,
            })
              .then((res) => {
                if (res.result !== 'success') {
                  return {
                    ...res.errors,
                    'update': Object.values(res.errors || {}).join(' '),
                  }
                } else {
                  setBlacklist({
                    emails: data.emails.value as any,
                    domains: data.domains.value as any,
                  })
                }
                return undefined;
              });
          }
          return Promise.resolve({})
        }} template={[
          {
            type: 'section',
            items: [
              {
                type: 'row',
                display: {
                  align: 'centre',
                  style: {
                    paddingBottom: 10
                  }
                },
                items: [
                  {
                    type: 'itemSet',
                    title: 'Emails',
                    id: 'emails',
                    value: blacklist.emails,
                  },
                  {
                    type: 'itemSet',
                    title: 'Domains',
                    id: 'domains',
                    value: blacklist.domains,
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            items: [
              {
                type: 'button',
                id: 'update',
                value: 'Save',
                onClick: 'update',
              },
            ]
          },

        ]}
        />}
      </Standard.StandardContent>
    </Block.Block>
  </HeaderPage>;
}
