import { useState, useEffect } from 'react';

export const useLoader = (requests: (() => Promise<any>)[]): boolean => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all(requests.map(r => r())).then(() => {
      setLoading(false);
    });
  }, []);

  return loading;
}