import React, { Component, useState } from 'react';
import { Navigate, redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Elements } from '../../components';

import { Form, Control } from '../../components/form';

import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

import { makeRequest } from '../../helper/request';
import { state } from '../../state/reducer/user';
import { regions } from './helper';
import { Modal, Button } from 'react-bootstrap';
import { useLoader } from '../../components/util/loader';

export interface Props extends HeaderProps {
  user: state,
};

interface filter {
  index: number,
  type: 'none' | 'active' | 'firstPlay' | 'festival' | 'excludeFestival' | 'region',
  range?: { 
    start: number,
    end: number,
  },
  value?: string,
  options?: { [key: string]: boolean },
  includeOtherSessions?: boolean,
};

export const ReportBase = (props: Props) => {
  const [index, setIndex] = useState(0);
  const [filters, setFilters] = useState<filter[]>([{
    index: 0,
    type: 'none'
  }]);
  const [festivals, setFestivals] = useState<{ [key: string]: string }>({});
  const [notice, setNotice] = useState(false);

  const loading = useLoader([() => makeRequest({
    type: 'admin.festival.list',
    token: props.user.accessToken,
  })
    .then((res) => {
      const festivals: { [key: string]: string } = {};
      if (res.result === 'success') {
        res.data.festivals.forEach((f: any) => {
          festivals[f.id] = f.name;
        });
      }
      setFestivals(festivals);
    })]);

  if (props.user.accessToken === undefined) {
    return <Navigate to="/" />;
  }
  if (loading) {
    return <Standard.StandardContent className='content-holder' style={{ flex: 1 }} >
      <Elements.Spinner/>
    </Standard.StandardContent>;
  }
  return <Block.Block alternate={true}>
    <Standard.StandardContent className='content-holder' style={{ flex: 1 }} >
      <Form submitHandler={(action, data) => {
        if (action === 'add.filter') {
          setFilters([...filters, { index: index, type: 'none'}]);
          setIndex(index+1);
          return Promise.resolve(undefined);
        } else if (action === 'run.report') {
          const include: any[] = [];
          const exclude: any[] = [];
          filters.map((f) => {
            if (f.type === 'active' || f.type === 'firstPlay') {
              include.push({
                type: f.type,
                range: { 
                  start: (f.range?f.range.start: Date.now()-60*60*24*7*1000)* 0.001,
                  end: (f.range?f.range.end: Date.now())* 0.001,
                },
                includeOtherSessions: f.includeOtherSessions,
              })
            } else if (f.type === 'festival') {
              include.push({
                type: f.type,
                festivals: Object.keys(f.options || {}),
              })
            } else if (f.type === 'excludeFestival') {
              exclude.push({
                type: 'festival',
                festivals: Object.keys(f.options || {}),
              })
            } else if (f.type === 'region') {
              include.push({
                type: f.type,
                region: f.value,
              })
            }
          });
          return makeRequest({
            type: 'admin.report.run',
            token: props.user.accessToken,
            include,
            exclude,
          })
            .then((res) => {
              if (res.result !== 'success') {
                
                return {
                  ...res.errors,
                  'run.report': res.error,
                }
              } else {
                setNotice(true);
              }
              return undefined;
            });
        }
        return Promise.resolve({})
      }} template={[
        {
          type: 'section',
          items: filters.map((f, filterIndex) => {
            const r: Control[] = [
              {
                type: 'dropdown',
                id: 'filter',
                value: f.type,
                options: {
                  'none': { value: 'Filter Type', disabled: true, default: true },
                  'active': 'Active',
                  'firstPlay': 'First Play',
                  'festival': 'Festival',
                  'excludeFestival': 'Exclude Festival',
                  'region': 'Region',
                },
                onChange: (value) => {
                  setFilters(filters.map(f => {
                    if (f.index === filterIndex) {
                      const res = { ...f };
                      // res.type = value;
                      if (res.type === 'active' || res.type === 'firstPlay') {
                        res.range = res.range || {
                          start: Date.now()*0.001-60*60*24*7,
                          end: Date.now()*0.001,
                        }
                        res.includeOtherSessions = false;
                      }
                      return res; 
                    }
                    return f;
                  }));
                }
              }
            ];
            if (f.type === 'active' || f.type === 'firstPlay') {
              r.push({
                type: 'datetime',
                id: 'start',
                title: 'Start',
                value: f.range? f.range.start: Date.now()*0.001-60*60*24*7,
                display: { group: { style: { paddingLeft: 20 }} },
                onChange: (value) => {
                  setFilters(filters.map(f => {
                    if (f.index === filterIndex) {
                      return { ...f, range: { ...(f.range || { end: Date.now()*0.001 }), start: value as number }}; 
                    }
                    return f;
                  }));
                }
              });
              r.push({
                type: 'datetime',
                id: 'end',
                title: 'End',
                value: f.range? f.range.end: Date.now()*0.001,
                onChange: (value) => {
                  setFilters(filters.map(f => {
                    if (f.index === filterIndex) {
                      return { ...f, range: { ...(f.range || { start: Date.now()*0.001-60*60*24*7 }), end: value as number }}; 
                    }
                    return f;
                  }));
                }
              });
              if (f.type === 'active') {
                r.push({
                  type: 'checkbox',
                  id: 'includeAll',
                  title: 'Include Other Sessions',
                  value: f.includeOtherSessions === true,
                  onChange: (value) => {
                    setFilters(filters.map(f => {
                      if (f.index === filterIndex) {
                        return { ...f, includeOtherSessions: value === true || value === 'true' }; 
                      }
                      return f;
                    }));
                  }
                });
              }
            } else if (f.type === 'festival' || f.type === 'excludeFestival') {
              Object.entries(festivals).forEach(([id, name], i) => {
                r.push({
                  id: `select.${i}`,
                  type: 'checkbox',
                  title: name,
                  value: (f.options || {})[id] === true,
                  display: { group: { style: { paddingLeft: 20, marginTop: 'auto', marginBottom: 'auto' }}},
                  onChange: (value) => {
                    setFilters(filters.map(f => {
                      if (f.index === filterIndex) {
                        return { ...f, options: { ...f.options, [id]: value === true || value === 'true' }}; 
                      }
                      return f;
                    }));
                  }
                });
              });
            } else if (f.type === 'region') {
              r.push({
                type: 'dropdown',
                id: 'region',
                title:'',
                value: f.value || 'AU',
                options: regions,
                display: { group: { style: { paddingLeft: 20 }}},
                onChange: (value) => {
                  setFilters(filters.map(f => {
                    if (f.index === filterIndex) {
                      return { ...f, value: `${value}`}; 
                    }
                    return f;
                  }));
                }
              });
            }
            return {
              type: 'row',
              props: { style: { justifyContent: 'flex-start' } },
              items: r
            };
          }),
        },
        {
          type: 'section',
          items: [
            {
              type: 'button',
              id: 'add.filter',
              value: 'Add Filter',
              onClick: 'add.filter'
            },
            {
              type: 'button',
              id: 'run.report',
              value: 'Run Report',
              onClick: 'run.report',
            },
          ]
        },
      ]} />
      <Modal key={'popup'} show={notice} onHide={() => {setNotice(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Report Submitted</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>The report is now running and will be emailed to you in a few minutes once ready.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="audioplay-secondary" onClick={() => {setNotice(false)}}>OK</Button>
        </Modal.Footer>
      </Modal>
    </Standard.StandardContent>
  </Block.Block>;
}

export const Report = connect((state: any) => ({ user: state.user }), { })(ReportBase);