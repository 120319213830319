import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { DataObject, Context, DataBinding, UserBinding  } from '@audioplay/client';
import * as Client from '@audioplay/client';

import { models, processor, Model } from '@audioplay/types';
import { useLoader } from '../util/loader';

export const getBinding = <M extends { id: string } = any, T extends DataObject<M> = DataObject<any>>(modelName: string, context:  Context): DataBinding<M,T> => {
  return (Client as any)[`${modelName}Binding`].getBinding(context) as DataBinding<M, T>;
}

export function useItemCollection<M extends { id: string }, T extends DataObject<M>>(binding: DataBinding<M, T>) {
  const [items, setItems] = useState<T[] | undefined>(undefined);
  
  useEffect(() => {
    const key = binding.bind((items) => {
      setItems(items);
    });
    return () => {
      setItems(undefined);
      binding.unbind(key);
    }
  }, [binding]);

  return { loading: items === undefined, items: items || [] }
}

export function useItemCollections<T extends { [key in keyof T]: DataBinding<any,any> }>(bindings: T): { loading: boolean, items: { [K in keyof T]: Awaited<ReturnType<T[K]['getItems']>> } } {
  const baseItems = (): { [K in keyof T]: Awaited<ReturnType<T[K]['getItems']>> } => {
    const r: any = {};
    Object.entries(bindings).forEach(([k,v]) => {
      r[k] = [];
    });
    return r;
  }
  
  const [items, setItems] = useState<{ [K in keyof T]: Awaited<ReturnType<T[K]['getItems']>> } | undefined>(undefined);
  
  useEffect(() => {
    if (Object.keys(bindings).length === 0){ 
      setItems({}  as any);
      return () => {};
    }
    const keys: { [key: string]: string } = {};
    let waiting = 0;
    const items: { [key: string]: any[] } = {};
    Object.entries(bindings).forEach(([k,v]) => {
      waiting ++;
      let loaded = false;
      keys[k] = (v as DataBinding<any,any>).bind(i => {
        if (!loaded) {
          loaded = true;
          waiting --;
        }
        items[k] = i;
        if (waiting === 0) {
          setItems(items as any);
        }
      });
    });

    return () => {
      setItems(undefined);
      Object.entries(bindings).forEach(([k,v]) => {
        (v as DataBinding<any,any>).unbind(keys[k]);
      });
    }
  }, Object.values(bindings));

  return { loading: items === undefined, items: items || baseItems() }
}

export interface Props {
  content: (header: HTMLDivElement | undefined, footer: HTMLDivElement | undefined) => React.ReactElement,
  save: () => Promise<void>,
  close: () => void,
  loading?: boolean,
}

export function Base(props: Props) {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);
  const [header,setHeader] = useState<HTMLDivElement | undefined>(undefined);
  const [footer,setFooter] = useState<HTMLDivElement | undefined>(undefined);

  useEffect(() => {
    if (headerRef.current) {
      setHeader(headerRef.current); 
    }
    if (footerRef.current) {
      setFooter(footerRef.current);
    }
  }, []);
    
  return <Modal show={true} onHide={() => props.close()} dialogClassName='audioplay-edit-model'>
    <Modal.Header>
      <div ref={headerRef}/>
    </Modal.Header>
    <Modal.Body>
      {props.loading === true?<Spinner animation='border'></Spinner>:props.content(header, footer)}
    </Modal.Body>
    <Modal.Footer>
      <div ref={footerRef}/>
    </Modal.Footer>
  </Modal>
}