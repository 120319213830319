

export const episodeInformation = [
  {
    title: 'The Turners\nEpisode 1',
    img: '/test/TheTurnersEpisode1Thumbnail.png',
    productName: 'TheTurners.Season1.Episode1',
    productIds: ['com.audioplay.theturners.episode1'],
    fullSeasonIds: ['com.audioplay.theturners.complete'],
    otherEpisodes: ['TheTurners.Season1.Episode1', 'TheTurners.Season1.Episode2', 'TheTurners.Season1.Episode3', 'TheTurners.Season1.Episode4']
  },
  {
    title: 'The Turners\nEpisode 2',
    img: '/test/TheTurnersEpisode2Thumbnail.png',
    productName: 'TheTurners.Season1.Episode2',
    productIds: ['com.audioplay.theturners.episode2'],
    fullSeasonIds: ['com.audioplay.theturners.complete'],
    otherEpisodes: ['TheTurners.Season1.Episode1', 'TheTurners.Season1.Episode2', 'TheTurners.Season1.Episode3', 'TheTurners.Season1.Episode4']
  },
  {
    title: 'The Turners\nEpisode 3',
    img: '/test/TheTurnersEpisode3Thumbnail.png',
    productName: 'TheTurners.Season1.Episode3',
    productIds: ['com.audioplay.theturners.episode3'],
    fullSeasonIds: ['com.audioplay.theturners.complete'],
    otherEpisodes: ['TheTurners.Season1.Episode1', 'TheTurners.Season1.Episode2', 'TheTurners.Season1.Episode3', 'TheTurners.Season1.Episode4']
  },
  {
    title: 'The Turners\nEpisode 4',
    img: '/test/TheTurnersEpisode4Thumbnail.png',
    productName: 'TheTurners.Season1.Episode4',
    productIds: ['com.audioplay.theturners.episode4'],
    fullSeasonIds: ['com.audioplay.theturners.complete'],
    otherEpisodes: ['TheTurners.Season1.Episode1', 'TheTurners.Season1.Episode2', 'TheTurners.Season1.Episode3', 'TheTurners.Season1.Episode4']
  },
  {
    title: 'Sun Runners\nEpisode 1',
    img: '/test/TheSunRunnersEpisode1Thumbnail.png',
    productName: 'TheSunRunners.Episode1',
    productIds: ['com.audioplay.thesunrunners.episode1'],
    fullSeasonIds: ['com.audioplay.thesunrunners.complete'],
    otherEpisodes: ['TheSunRunners.Episode1', 'TheSunRunners.Episode2', 'TheSunRunners.Episode3', 'TheSunRunners.Episode4', 'TheSunRunners.Episode5', 'TheSunRunners.Episode6']
  },
  {
    title: 'Sun Runners\nEpisode 2',
    img: '/test/TheSunRunnersEpisode2Thumbnail.png',
    productName: 'TheSunRunners.Episode2',
    productIds: ['com.audioplay.thesunrunners.episode2'],
    fullSeasonIds: ['com.audioplay.thesunrunners.complete'],
    otherEpisodes: ['TheSunRunners.Episode1', 'TheSunRunners.Episode2', 'TheSunRunners.Episode3', 'TheSunRunners.Episode4', 'TheSunRunners.Episode5', 'TheSunRunners.Episode6']
  },
  {
    title: 'Sun Runners\nEpisode 3',
    img: '/test/TheSunRunnersEpisode3Thumbnail.png',
    productName: 'TheSunRunners.Episode3',
    productIds: ['com.audioplay.thesunrunners.episode3'],
    fullSeasonIds: ['com.audioplay.thesunrunners.complete'],
    otherEpisodes: ['TheSunRunners.Episode1', 'TheSunRunners.Episode2', 'TheSunRunners.Episode3', 'TheSunRunners.Episode4', 'TheSunRunners.Episode5', 'TheSunRunners.Episode6']
  },
  {
    title: 'Sun Runners\nEpisode 4',
    img: '/test/TheSunRunnersEpisode4Thumbnail.png',
    productName: 'TheSunRunners.Episode4',
    productIds: ['com.audioplay.thesunrunners.episode4'],
    fullSeasonIds: ['com.audioplay.thesunrunners.complete'],
    otherEpisodes: ['TheSunRunners.Episode1', 'TheSunRunners.Episode2', 'TheSunRunners.Episode3', 'TheSunRunners.Episode4', 'TheSunRunners.Episode5', 'TheSunRunners.Episode6']
  },
  {
    title: 'Sun Runners\nEpisode 5',
    img: '/test/TheSunRunnersEpisode5Thumbnail.png',
    productName: 'TheSunRunners.Episode5',
    productIds: ['com.audioplay.thesunrunners.episode5'],
    fullSeasonIds: ['com.audioplay.thesunrunners.complete'],
    otherEpisodes: ['TheSunRunners.Episode1', 'TheSunRunners.Episode2', 'TheSunRunners.Episode3', 'TheSunRunners.Episode4', 'TheSunRunners.Episode5', 'TheSunRunners.Episode6']
  },
  {
    title: 'Sun Runners\nEpisode 6',
    img: '/test/TheSunRunnersEpisode6Thumbnail.png',
    productName: 'TheSunRunners.Episode6',
    productIds: ['com.audioplay.thesunrunners.episode6'],
    fullSeasonIds: ['com.audioplay.thesunrunners.complete'],
    otherEpisodes: ['TheSunRunners.Episode1', 'TheSunRunners.Episode2', 'TheSunRunners.Episode3', 'TheSunRunners.Episode4', 'TheSunRunners.Episode5', 'TheSunRunners.Episode6']
  },
  {
    title: 'Pooch on the Loose\nEpisode 1',
    img: '/test/PoochOnTheLooseEpisode1Thumbnail.png',
    productName: 'PoochOnTheLoose.Episode1',
    productIds: ['com.audioplay.poochontheloose.episode1'],
    fullSeasonIds: ['com.audioplay.poochontheloose.complete'],
    otherEpisodes: ['PoochOnTheLoose.Episode1', 'PoochOnTheLoose.Episode2', 'PoochOnTheLoose.Episode3', 'PoochOnTheLoose.Episode4', 'PoochOnTheLoose.Episode5']
  },
  {
    title: 'Pooch on the Loose\nEpisode 2',
    img: '/test/PoochOnTheLooseEpisode2Thumbnail.png',
    productName: 'PoochOnTheLoose.Episode2',
    productIds: ['com.audioplay.poochontheloose.episode2'],
    fullSeasonIds: ['com.audioplay.poochontheloose.complete'],
    otherEpisodes: ['PoochOnTheLoose.Episode1', 'PoochOnTheLoose.Episode2', 'PoochOnTheLoose.Episode3', 'PoochOnTheLoose.Episode4', 'PoochOnTheLoose.Episode5']
  },
  {
    title: 'Pooch on the Loose\nEpisode 3',
    img: '/test/PoochOnTheLooseEpisode3Thumbnail.png',
    productName: 'PoochOnTheLoose.Episode3',
    productIds: ['com.audioplay.poochontheloose.episode3'],
    fullSeasonIds: ['com.audioplay.poochontheloose.complete'],
    otherEpisodes: ['PoochOnTheLoose.Episode1', 'PoochOnTheLoose.Episode2', 'PoochOnTheLoose.Episode3', 'PoochOnTheLoose.Episode4', 'PoochOnTheLoose.Episode5']
  },
  {
    title: 'Pooch on the Loose\nEpisode 4',
    img: '/test/PoochOnTheLooseEpisode4Thumbnail.png',
    productName: 'PoochOnTheLoose.Episode4',
    productIds: ['com.audioplay.poochontheloose.episode4'],
    fullSeasonIds: ['com.audioplay.poochontheloose.complete'],
    otherEpisodes: ['PoochOnTheLoose.Episode1', 'PoochOnTheLoose.Episode2', 'PoochOnTheLoose.Episode3', 'PoochOnTheLoose.Episode4', 'PoochOnTheLoose.Episode5']
  },
  {
    title: 'Pooch on the Loose\nEpisode 5',
    img: '/test/PoochOnTheLooseEpisode5Thumbnail.png',
    productName: 'PoochOnTheLoose.Episode5',
    productIds: ['com.audioplay.poochontheloose.episode5'],
    fullSeasonIds: ['com.audioplay.poochontheloose.complete'],
    otherEpisodes: ['PoochOnTheLoose.Episode1', 'PoochOnTheLoose.Episode2', 'PoochOnTheLoose.Episode3', 'PoochOnTheLoose.Episode4', 'PoochOnTheLoose.Episode5']
  },
]