import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Form, Control, Section, Row, Custom, isControl, FormController, SubmitHandler, ControlValue } from '../form';

type Path = (string | number)[];

type LoadValues<keys extends string> = () => { [key in keys]: ControlValue | ControlValue[] }
type SaveValues<keys extends string> = (values: { [key in keys]: ControlValue | ControlValue[] }) => { [key: string]: string } | undefined

export class FormBinding<T> {
  private fields: { 
    [key: string]: {
      id: string,
      path: Path,
      generate: boolean,
      generateOnEmpty: boolean,
    }
  } = {};

  private fieldSets: { 
    [key: string]: {
      inputValues: { [key: string]: ControlValue | ControlValue[] },
      valueMap: { [key: string]: string },
      setValue: SaveValues<string>,
    }
  } = {};

  private customActions: {
    [key: string]: () => { [key: string]: string } | undefined,
  } = {};
  private backing: T;

  public constructor(backingData: T) {
    this.backing = backingData;
  }

  public resolve(state: Parameters<SubmitHandler>[1]): { [key: string]: string } | undefined {
    const errors: { [key: string]: string } = {};
    Object.values(this.fields).forEach((field) => {
      const p = [...field.path];
      const final = p.pop();
      if (final !== undefined) {
        let value: undefined | ControlValue = undefined;
        let v = state[field.id];
        if (v) {
          value = v.value;
        }
        const emptyValue = value === undefined || value === null;
        let o = this.backing as any;
        p.forEach((k) => {
          if (o !== undefined && o !== null && o[k] === undefined && field.generate && (field.generateOnEmpty || !emptyValue)) {
            o[k] = {};
          }
          o = o === undefined || o === null? o: o[k];
        });
        if (o !== undefined && o !== null && (o.hasOwnProperty(final) || field.generateOnEmpty || !emptyValue)) {
          o[final] = value;
        }
      }
    });

    Object.values(this.fieldSets).forEach((set) => {
      const values = { ...set.inputValues };
      Object.entries(set.valueMap).forEach(([name, id]) => {
        let value: undefined | ControlValue = undefined;
        let v = state[id];
        if (v) {
          value = v.value;
        }
        values[name] = value;
      });
      Object.assign(errors, set.setValue(values));
    });

    Object.values(this.customActions).forEach((a) => {
      Object.assign(errors, a());
    })
    return Object.keys(errors).length === 0? undefined: errors;
  }

  public bindingAction(key: string, action: () => { [key: string]: string } | undefined) {
    this.customActions[key] = action;
  }

  public bind<T extends Control>(node: T, path: Path, options: { continuousMapping?: boolean, generateIntermediateNodes?: boolean, generateOnEmpty?: boolean, overwriteInputValue?: ControlValue | ControlValue[] } = {}): T {
    let value = this.backing as any;
    path.forEach((k) => {
      value = value === undefined || value === null? value: value[k];
    });
    if (options.hasOwnProperty('overwriteInputValue')) {
      value = options.overwriteInputValue;
    }
    const key = path.map(i => i.toString()).join('.');
    const data = this.fields[key];
    if (node.id === undefined) {
      node.id = data === undefined? uuid(): data.id;
    }
    this.fields[key] = {
      id: node.id,
      path,
      generate: options.generateIntermediateNodes === true,
      generateOnEmpty: options.generateOnEmpty !== false,
    }
    node.value = value;
    if (options.continuousMapping === true) {
      const p = [...path];
      const final = p.pop();
      if (final !== undefined) {
        const c = node.onChange;
        node.onChange = (value, controller, information) => {
          let v = this.backing as any;
          const emptyValue = value === undefined || value === null;
          p.forEach((k) => {
            if (v !== undefined && v !== null && v[k] === undefined && options.generateIntermediateNodes === true && (options.generateOnEmpty !== false || !emptyValue)) {
              v[k] = {};
            }
            v = v === undefined || v === null? v: v[k];
          });
          if (v !== undefined && v !== null && (v.hasOwnProperty(final) || options.generateOnEmpty !== false || !emptyValue)) {
            v[final] = value;
          }
          if (c) {
            c(value, controller, information);
          }
        }
      }
    }
    return node;
  }

  public bindCollection<T extends Control, keys extends string>(node: T, key: string, loadValues: LoadValues<keys>, saveValues: SaveValues<keys>, continuousMapping?: boolean): T;
  public bindCollection<T extends Row, keys extends string>(node: T, key: string, loadValues: LoadValues<keys>, saveValues: SaveValues<keys>, continuousMapping?: boolean): T;
  public bindCollection<T extends Section, keys extends string>(node: T, key: string, loadValues: LoadValues<keys>, saveValues: SaveValues<keys>, continuousMapping?: boolean): T;
  public bindCollection<T extends Custom, keys extends string>(node: T, key: string, loadValues: LoadValues<keys>, saveValues: SaveValues<keys>, continuousMapping?: boolean): T;
  public bindCollection<T extends Control | Row | Section | Custom, keys extends string>(node: T, key: string, loadValues: LoadValues<keys>, saveValues: SaveValues<keys>, continuousMapping?: boolean): T;
  public bindCollection<T extends Control | Row | Section | Custom, keys extends string>(node: T, key: string, loadValues: LoadValues<keys>, saveValues: SaveValues<keys>, continuousMapping = false): T {
    const values: { [key: string]: ControlValue | ControlValue[] } = loadValues();
    const set: {
      inputValues: { [key: string]: ControlValue | ControlValue[] },
      valueMap: { [key: string]: string },
      setValue: SaveValues<string>,
    } = {
      valueMap: this.fieldSets[key]? this.fieldSets[key].valueMap: {},
      setValue: saveValues,
      inputValues: values,
    };
    this.fieldSets[key] = set;
    const updateObject = (item: { [key: string]: any } | any[]) => {
      if (Array.isArray(item)) {
        item.forEach((v,n) => {
          if (typeof v === 'string' && v.startsWith('#[') && v.endsWith(']')) {
            const k = v.substring(2,v.length-1);
            (item as any[])[n] = values[k];
          } else if (typeof v === 'object' && v !== null) {
            updateObject(v);
          }
        });
        return;
      }
      Object.entries(item).forEach(([n,v]) => {
        if (typeof v === 'string' && v.startsWith('#[') && v.endsWith(']')) {
          const k = v.substring(2,v.length-1);
          (item as any)[n] = values[k];
        } else if (typeof v === 'object' && v !== null) {
          updateObject(v);
        }
      });
    }
    const updateNode = (node: Control | Row | Section | Custom) => {
      let maintain: string | undefined;

      Object.entries(node).forEach(([n,v]) => {
        if (typeof v === 'string' && v.startsWith('#[') && v.endsWith(']')) {
          const k = v.substring(2,v.length-1);
          (node as any)[n] = values[k];
          if (n === 'value') {
            maintain = k;
          }
        } else if (typeof v === 'object' && v !== null && n !== 'items') {
          updateObject(v);
        }
      });
      if (maintain !== undefined && isControl(node)) {
        if (node.id === undefined) {
          node.id = set.valueMap[maintain] || uuid();
        }
        set.valueMap[maintain] = node.id;
      }
      if (maintain !== undefined && continuousMapping && isControl(node)) {
        const c = node.onChange;
        node.onChange = (value, controller, information) => {
          const values = { ...set.inputValues };
          Object.entries(set.valueMap).forEach(([name, id]) => {
            values[name] = controller.getValue(id);
          });
          set.setValue(values);
          if (c) {
            c(value, controller, information);
          }
        }
      }

      if (node.type === 'row' || node.type === 'section') {
        node.items.forEach((n) => {
          updateNode(n);
        });
      }
    }
    updateNode(node);

    return node;
  }
}

export function useBinding<T extends { id: string }>(item: T) {
  const [binding, setBinding] = useState(new FormBinding(item));
  useEffect(() => {
    setBinding(new FormBinding(item));
  }, [item.id]);
  return binding;
}