import React from 'react';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';

import { App } from './app/App';
import { AppRouter } from './app/router/portal.router';

import * as serviceWorker from './serviceWorker';

import { Provider } from "react-redux";
import stateLoad from "./state";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './css/index.scss';

const { store, persistor } = stateLoad();

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement); // createRoot(container!) if you use TypeScript
root.render(<React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App router={AppRouter}/>
    </PersistGate>
  </Provider>
</React.StrictMode>);

serviceWorker.unregister();
