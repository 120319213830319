import { Route } from 'react-router-dom';

import * as Admin from '../../admin';
import { RouteBundleProps } from '../common';

export const admin = (props: RouteBundleProps) => {
  return [
    <Route path="/admin/login" element={<Admin.Login/>} />,
    <Route path="/admin/reports" element={<Admin.Reports/>} />,
    <Route path="/admin/report" element={<Admin.Report/>} />,
    <Route path="/admin/deviceInfo" element={<Admin.DeviceInfo/>} />,
    <Route path="/admin/analyticsConfiguration" element={<Admin.AnalyticsConfiguration/>} />,
    <Route path="/admin/userReport" element={<Admin.UserReport/>} />,
    <Route path="/admin" element={<Admin.Dashboard/>} />,
    <Route path="/admin/funnel" element={<Admin.Funnel context={props.context}/>} />,
  ];
}