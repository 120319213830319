import { useCallback, useEffect, useRef, useState } from 'react';
import { FestivalBinding, CampaignBinding, UserBinding, Context, User as UserModel, Festival, Campaign, makeRequest } from '@audioplay/client';
import { useNavigate } from 'react-router-dom';
import { BoxArrowUpRight, ChevronUp, ChevronDown, DashSquareFill, PlusSquareFill } from 'react-bootstrap-icons';

import { Base as ModelBase, useItemCollection, useItemCollections } from './Base';
import { FormBinding, useBinding } from './FormBinding';
import { Form, Control, Section, Row, FormController, ControlOptions } from '../../components/form';
import { episodeInformation } from './Data';

export interface Props {
  item: UserModel,
  newItem: boolean,
  context: Context,
  closed: () => void
}

export function User(props: Props) {
  const { loading, items } = useItemCollections({
    festivals: FestivalBinding.getBinding(props.context),
    campaigns: CampaignBinding.getBinding(props.context)
  });
  const binding = useBinding(props.item);
  const [festivalAdjustments, setFestivalAdjustments] = useState<{ [key: string]: { state: 'remove' | 'add' }}>({});
  const navigate = useNavigate();
  const festivalStateRef = useRef(festivalAdjustments);
  festivalStateRef.current = festivalAdjustments;
  const episodesActive = useRef(false);
  const festivalsActive = useRef(false);


  const adjustFestivals = (festivalId: string, action: 'add' | 'remove') => {
    if (festivalStateRef.current[festivalId]) {
      if (festivalStateRef.current[festivalId].state !== action) {
        delete festivalStateRef.current[festivalId];
        setFestivalAdjustments({ ...festivalStateRef.current });
      }
    } else {
      setFestivalAdjustments({ ...festivalStateRef.current, [festivalId]:  { state: action }});
    }
  };

  const setFestivalAddFilter = (festivalId: string, action: 'add' | 'remove', controller: FormController) => {
    const options: ControlOptions = {
      '': { default: true, disabled: true, value: '' },
    }
    let noOptions = true;
    items.festivals.filter(f => {
      if (f.end !== undefined && f.end !== null && f.end < Date.now()*0.001) {
        return false;
      }
      if (f.id === festivalId) {
        return action === 'remove';
      }
      if (festivalStateRef.current[f.id]) {
        return festivalStateRef.current[f.id].state === 'remove';
      }
      return (props.item.festivalAccess || {})[f.id] === undefined;
    }).forEach((f) => {
      noOptions = false;
      options[f.id] = f.name || f.id;
    });
    controller.setState('festival.add.select', { options, value: undefined, display: { hidden: noOptions } });
    controller.setState('festival.add.button', { display: { hidden: noOptions } });
  };
    
  const template: (Control | Section | Row)[] = [];
  if (!loading) {
    template.push({
      type: 'section',
      title: 'User Data',
      items: [{
        type: 'row',
        items:[
          binding.bind({
            type: 'text',
            title: 'Name',
          }, ['name']),
          binding.bind({
            type: 'text',
            display: {
              disabled: !props.newItem,
            },
            title: 'Email'
          }, ['email']),
        ]
      }]
    });

    let toggleEpisodes = (controller: FormController, active?: boolean) => {
      if (active === episodesActive.current) {
        return;
      }
      episodesActive.current = active===undefined?!episodesActive.current:active;
      controller.setState('episode.title.indicator', { active: episodesActive.current } as any)
      const parent = controller.getParent('episode.title.row');
      if (parent) {
        controller.getChildren(parent).forEach((i) => {
          if (i !== 'episode.title.row') {
            controller.setHidden(i, !episodesActive.current);
          }
        })
      }
    }
    let toggleFestivals = (controller: FormController, active?: boolean) => {
      if (active === festivalsActive.current) {
        return;
      }
      festivalsActive.current = active===undefined?!festivalsActive.current:active;
      controller.setState('festival.title.indicator', { active: festivalsActive.current } as any)
      const parent = controller.getParent('festival.title.row');
      if (parent) {
        controller.getChildren(parent).forEach((i) => {
          if (i !== 'festival.title.row') {
            controller.setHidden(i, !festivalsActive.current);
          }
        })
      }
    }
    const access: Row[] = episodeInformation.map(i => {
      return binding.bindCollection({
        type: 'row',
        display: {
          hidden: !episodesActive.current,
        },
        items: [
          {
            type: 'img',
            value: i.img,
            display: {
              disabled: true,
              props: {
                imageWidth: '100px',
                imageHeight: '100px'
              }
            }
          },
          {
            type: 'label',
            value: i.title,
            display: {
              style: {
                width: '250px',
              }
            }
          },
          {
            type: 'checkbox',
            value: '#[access]',
            optional: true,
            display: {
              style: {
                width: '65px',
              }
            }
          },
          {
            type: 'checkbox',
            value: '#[permanent]',
            optional: true,
            onChange: (v, controller, info) => {
              if (typeof v === 'boolean') {
                controller.setHidden(controller.getChildren(info.parentId)[4], v);
              } else {
                controller.setHidden(controller.getChildren(info.parentId)[4], false);
              }
            },
            display: {
              style: {
                width: '85px',
              }
            }
          },
          {
            type: 'datetime',
            value: '#[end]',
            optional: true,
            display: {
              hidden: '#[permanent]' as any,
              group: {
                style: {
                  width: '250px',
                }
              }
            }
          }
        ]
      }, 'episode.'+i.productName, () => {
        let a;
        a = (props.item.episodes || {})[i.productName];
        if (a !== undefined) {
          if (a.permanent !== true && (a.validUntil === undefined || a.validUntil*1000 < Date.now())) {
            a = undefined;
          }
        }
        return {
          access: a !== undefined,
          permanent: (a !== undefined? a.permanent: undefined),
          end: (a !== undefined? a.validUntil: undefined),
        };
      }, (values) => {
        if (props.item.episodes === undefined || props.item.episodes === null) {
          props.item.episodes = {};
        }
        const now = Date.now()*0.001;
        if (values.access) {
          if (values.permanent) {
            if (props.item.episodes[i.productName] === undefined || props.item.episodes[i.productName].permanent !== true) {
              props.item.episodes[i.productName] = {
                productIds: i.productIds,
                permanent: true,
                validUntil: now+90*24*60*60,
              }
            }
          } else if (typeof values.end === 'number' && values.end > now) {
            if (props.item.episodes[i.productName] === undefined || props.item.episodes[i.productName].permanent === true || props.item.episodes[i.productName].validUntil !== values.end) {
              props.item.episodes[i.productName] = {
                productIds: i.productIds,
                validUntil: values.end,
              }
            }
          }
        } else {
          delete props.item.episodes[i.productName];
        }
        return undefined;
      });
    })
    
    template.push({
      id: 'episode.section',
      type: 'section',
      items: [{
        type: 'row',
        id: 'episode.title.row',
        onClick: (controller) => {
          toggleEpisodes(controller);
          toggleFestivals(controller, false);
        },
        display: {
          style: {
            justifyContent: 'center',
            alignItems: 'center',
          }
        },
        items: [
          {
            type: 'label',
            value: 'Episodes',
            display: {
              classNames: 'title text'
            }
          },
          {
            id: 'episode.title.indicator',
            type: 'custom',
            render: (props: any) => {
              return props.active? <ChevronDown size={30}></ChevronDown>: <ChevronUp size={30}></ChevronUp>;
            },
            active: episodesActive
          } as any,
        ],
      },
      {
        id: 'episode.label.row',
        type: 'row',
        display: {
          hidden: true,
        },
        items:[
          {
            type: 'label',
            value: '',
            display: {
              style: {
                width: '100px',
              }
            }
          },
          {
            type: 'label',
            value: 'Episode',
            display: {
              style: {
                width: '250px',
              }
            }
          },
          {
            type: 'label',
            value: 'Access',
            display: {
              style: {
                width: '65px',
              }
            }
          },
          {
            type: 'label',
            value: 'Permanent',
            display: {
              style: {
                width: '85px',
              }
            }
          },
          {
            type: 'label',
            value: 'Access End',
            display: {
              style: {
                width: '250px',
              }
            }
          },
        ]
      },
      ...access]
    });

    
    
    const festivalAccess: Row[] = [...Object.entries(props.item.festivalAccess || {})
      .map(([id,data]) => {
        const festival = items.festivals.find(f => f.id === id);
        return { festival, data, id }
      })
      .filter(({ festival, data, id }) => {
        if (festival && festivalAdjustments[festival.id] && festivalAdjustments[festival.id].state === 'remove') {
          return false;
        }
        return festival !== undefined;
      }),
      ...Object.entries(festivalAdjustments)
        .map(([id,data]) => {
          const festival = items.festivals.find(f => f.id === id);
          return { festival, data, id }
        })
        .filter(({ festival, data, id }) => {
          if (data.state === 'remove') {
            return false;
          }
          return festival !== undefined;
        })
      ]
      .map(({ festival: f, id }) => {
        const festival = f as Festival;
        let img: string | undefined = undefined;
        if (festival.appConfiguration && festival.appConfiguration.images) {
          const images = festival.appConfiguration.images;
          ['HeaderCard', 'Logo', 'Icon'].forEach(n => {
            const i = images[n]
            if (i !== undefined && img === undefined) {
              img = i.file;
            }
          })
          if (img === undefined && Object.values(festival.appConfiguration.images).length > 0 && Object.values(festival.appConfiguration.images)[0]) {
            img = festival.appConfiguration.images[0].file;
          }
        }
        return {
          id: festival.id,
          type: 'row',
          display: {
            hidden: !festivalsActive.current,
          },
          items: [
            {
              type: 'img',
              value: img && !img.startsWith('http')?process.env.DEFAULT_ASSET_URL+'/'+img:img,
              display: {
                disabled: true,
                props: {
                  imageWidth: '100px',
                  imageHeight: '100px'
                }
              }
            },
            {
              type: 'label',
              value: festival.name || '',
              display: {
                style: {
                  width: '300px',
                }
              }
            },
            {
              type: 'datetime',
              disabled: true,
              value: festival.start? festival.start: undefined,
              display: {
                disabled: true,
              }
            },
            {
              type: 'datetime',
              disabled: true,
              value: festival.end? festival.end: undefined,
              display: {
                disabled: true,
              }
            },
            {
              type: 'custom',
              render: (p) => <DashSquareFill onClick={p.click} size={24} color='red' style={{alignSelf: 'center'}}/>,
              onClick: (controller, information) => {
                adjustFestivals(festival.id, 'remove');
                setFestivalAddFilter(festival.id, 'remove', controller)
              },
            },
            {
              type: 'custom',
              render: (p) => <BoxArrowUpRight onClick={p.click} size={24} style={{alignSelf: 'center'}}></BoxArrowUpRight>,
              onClick: () => {
                navigate(`/admin/festival`, { state: { itemId: id} })
              },
            }
          ]
        }
      });
    
    const options: ControlOptions = {
      '': { default: true, disabled: true, value: '' },
    }
    let noOptions = true;
    items.festivals.filter(f => {
      if (f.end !== undefined && f.end !== null && f.end < Date.now()*0.001) {
        return false;
      }
      if (festivalAdjustments[f.id]) {
        return festivalAdjustments[f.id].state === 'remove';
      }
      return (props.item.festivalAccess || {})[f.id] === undefined;
    }).forEach((f) => {
      options[f.id] = f.name || f.id;
      noOptions = false
    });

    template.push({
      type: 'section',
      items: [
        {
          type: 'row',
          id: 'festival.title.row',
          onClick: (controller) => {
            toggleFestivals(controller);
            toggleEpisodes(controller, false);
          },
          display: {
            style: {
              justifyContent: 'center',
              alignItems: 'center',
            }
          },
          items: [
            {
              type: 'label',
              value: 'Festivals',
              display: {
                classNames: 'title text'
              }
            },
            {
              id: 'festival.title.indicator',
              type: 'custom',
              render: (props: any) => {
                return props.active? <ChevronDown size={30}></ChevronDown>: <ChevronUp size={30}></ChevronUp>;
              },
              active: episodesActive
            } as any,
          ],
        },
        {
          id: 'festival.add.row',
          type: 'row',
          display: {
            hidden: true,
          },
          items:[
            {
              id: 'festival.add.select',
              type: 'dropdown',
              optional: true,
              options,
              display: {
                hidden: noOptions
              }
            },
            {
              id: 'festival.add.button',
              type: 'custom',
              render: (p) => <PlusSquareFill onClick={p.click} size={24} color='blue' style={{alignSelf: 'center'}}/>,
              onClick: (controller, information) => {
                const v = controller.getValue('festival.add.select');
                if (v) {
                  adjustFestivals(v.toString(), 'add');
                  setFestivalAddFilter(v.toString(), 'add', controller);
                }
              },
              display: {
                hidden: noOptions
              }
            },
          ]
        },
        {
          id: 'festival.label.row',
          type: 'row',
          display: {
            hidden: true,
          },
          items:[
            {
              type: 'label',
              value: '',
              display: {
                style: {
                  width: '100px',
                }
              }
            },
            {
              type: 'label',
              value: 'Festival',
              display: {
                style: {
                  width: '300px',
                }
              }
            },
            {
              type: 'label',
              value: 'Access Start',
              display: {
                style: {
                  width: '250px',
                }
              }
            },
            {
              type: 'label',
              value: 'Access End',
              display: {
                style: {
                  width: '250px',
                }
              }
            },
          ]
        },
        ...festivalAccess,
      ]
    });
    const campaignAccess: Control[] = Object.entries(props.item.campaigns || {})
      .map(([id,data]) => {
        const campaign = items.campaigns.find(f => f.id === id);
        return { campaign, data, id }
      })
      .filter(({ campaign, data, id }) => {
        return campaign !== undefined;
      })
      .map(({ campaign: f, data, id }) => {
        const campaign = f as Campaign;
        let img: string | undefined = undefined;
        return {
          type: 'label',
          value: campaign.name || '',
          display: {
            group: {
              style: {
                borderWidth: 1,
                borderColor: '#aaa',
                borderStyle: 'solid',
                padding: '5px',
                borderRadius: 5
              }
            }
          }
        }
      });
    if (campaignAccess.length > 0) {
      template.push({
        type: 'section',
        title: 'Campaigns',
        items: [
        {
          type: 'row',
          items: campaignAccess
        },
      ]
      });
    }

    template.push({
      type:'label',
      value: props.newItem? 'NEW USER': (props.item.name || ''),
      display: {
        section: 'header',
        style: {
          fontSize: 'x-large',
          fontWeight: 600
        }
      }
    });
    template.push({
      type: 'row',
      display: {
        section: 'footer',
      },
      items: [
        {
          type: 'button',
          value: 'CLOSE',
          onClick: 'close',
          validationRequired: false,
          display: {
            props: {
              variant: "secondary"
            }
          }
        },
        {
          id: 'saveButton',
          type: 'button',
          value: 'SAVE CHANGES',
          onClick: 'save',
        }
      ]
    });
  }
  
  return <ModelBase
    key={'User.Form'}
    close={() => { props.closed() }}
    save={() => Promise.resolve()}
    loading={loading}
    content={(header, footer) =>
      <Form
        headerElement={header}
        footerElement={footer}
        submitHandler={(action, data, controller) => {
          if (action === 'close') {
            props.closed();
          } else if (action === 'save') {
            const e = binding.resolve(data);
            if (e) {
              return Promise.resolve(e);
            }
            const festivals = props.item.festivalAccess || {};
            Object.entries(festivalStateRef.current).forEach(([id,f]) => {
              if (f.state === 'remove') {
                delete festivals[id];
              }
            });
            return props.item.save(props.context).then((r) => {
              if (r) {
                if (props.item.festivalAccess === undefined || props.item.festivalAccess === null) {
                  props.item.festivalAccess = {};
                }
                const fa = props.item.festivalAccess;
                const now = Date.now()*0.001;
                return Promise.all(Object.entries(festivalStateRef.current).map(([id,f]) => {
                  if (f.state === 'add') {
                    fa[id] = {
                      activated: now,
                      start: now,
                      end: now,
                      version: 1,
                    };
                    return makeRequest('account.add.festival', {
                      userId: props.item.id,
                      festivalId: id,
                    });
                  }
                  return undefined;
                })).then(() => {
                  UserBinding.getBinding(props.context).objectUpdated(props.item.getState());
                  props.closed();
                  return undefined;
                });
              } else {
                return {
                  'saveButton': 'Could not save successfully',
                }
              }
            })
            .catch(() => {
              return {
                'saveButton': 'Could not save successfully',
              }
            })
          }
          return Promise.resolve(undefined);
        }} 
        template={[
          {
            type: 'section',
            items: template,
          }
        ]}
        templateLockId={props.item.id}
      />
    }
  />
}