// import { Graph, Data, SVG, Point } from '@octopus/graph';
// import { DataCache, makeRequest, ReportModel, ReportDataModel, DataColumValueModel, DataColumModel, FestivalBinding, Context } from '@audioplay/client';
// import { useCallback, useState } from 'react';
// import { Col } from 'react-bootstrap';

// import { Navigate, useNavigate, useLocation } from 'react-router-dom';
// import { connect } from 'react-redux';

// import { Form } from '../../components/form/Form';
// import { Block, Standard } from '../../components/content';
// import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';
// import { Dropdown } from '../../components/form/Control/Controls/Dropdown';
// import { useItemCollections } from '../../components/model/Base';



// import { login } from '../../state/action/user';
// import { state } from '../../state/reducer/user';
// import { reportData } from '@audioplay/types/lib/apis';

// export interface Props extends HeaderProps {
//   context: Context,
// };

// interface ReportState {
//   lastReportCall: number,
//   reports: { [key: string]: ReportModel },
//   lastReportDataCall: number,
//   reportData: { [key: string]: {
//     reportData: ReportDataModel,
//     lastDataUpdate: number,
//     data: DataColumValueModel[][],
//   } },
// }

// const addValue = (value: DataColumValueModel, total: DataColumValueModel | undefined, column: DataColumModel | undefined): DataColumValueModel => {
//   if (total === undefined) {

//   }
//   if (column === undefined) {
//     if (typeof value === 'object') {
//       const r: {
//         [key: string]: boolean;
//       } | {
//         [key: string]: DataColumValueModel;
//       } = {};
//       if (typeof total === 'object') {
//         Object.entries(total).forEach(([k,v]) => {
//           r[k] = addValue(v,undefined,undefined);
//         })
//       }
//       if (typeof value === 'object') {
//         Object.entries(value).forEach(([k,v]) => {
//           r[k] = addValue(v,r[k],undefined);
//         })
//       }
//       return r;
//     } else if (typeof value === 'boolean') {
//       return (value === true || total === true);
//     } else if (typeof total === 'string' && typeof value === 'string') {
//       return total+value;
//     } else if (typeof total === 'number' && typeof value === 'number') {
//       return total+value;
//     }
//     return value;
//   }
//   if (column.type === 'set' || column.type === 'subSet') {
//     const r: {
//       [key: string]: boolean;
//     } | {
//       [key: string]: DataColumValueModel;
//     } = {};
//     if (typeof total === 'object') {
//       Object.entries(total).forEach(([k,v]) => {
//         r[k] = addValue(v,undefined,column.subStructure);
//       })
//     }
//     if (typeof value === 'object') {
//       Object.entries(value).forEach(([k,v]) => {
//         r[k] = addValue(v,r[k],column.subStructure);
//       })
//     }
//     return r;
//   } else if (typeof value === 'boolean') {
//     return (value === true || total === true);
//   } else if (typeof total === 'string' && typeof value === 'string') {
//     return total+value;
//   } else if (typeof total === 'number' && typeof value === 'number') {
//     if (column.aggregation === 'min') {
//       return Math.min(total,value)
//     }
//     if (column.aggregation === 'max') {
//       return Math.max(total,value)
//     }
//     return total+value;
//   }
//   return value;
// }

// const getValue = (value: DataColumValueModel | undefined, column: DataColumModel | undefined): number | undefined => {
//   if (column && column.aggregation === 'unique') {
//     if (typeof value === 'object') {
//       return Object.keys(value).length;
//     }
//     if (typeof value === 'number') {
//       return value;
//     } else if (typeof value === 'boolean') {
//       return value? 1: 0;
//     }
//     return undefined;
//   } else if (typeof value === 'object') {
//     if (column && column.aggregation === 'min') {
//       let r: number | undefined = undefined;
//       Object.values(value).forEach((v) => {
//         const sv = getValue(v,undefined);
//         r = r === undefined || sv === undefined? sv: Math.min(r,sv);
//       });
//       return r;
//     }
//     if (column && column.aggregation === 'min') {
//       let r: number | undefined = undefined;
//       Object.values(value).forEach((v) => {
//         const sv = getValue(v,undefined);
//         r = r === undefined || sv === undefined? sv: Math.max(r,sv);
//       });
//       return r;
//     }
//     let r: number | undefined = undefined;
//     Object.values(value).forEach((v) => {
//       const sv = getValue(v,undefined);
//       r = r === undefined? sv: sv === undefined? r: r+sv;
//     });
//     return r;
//   }
//   if (typeof value === 'number') {
//     return value;
//   } else if (typeof value === 'boolean') {
//     return value? 1: 0;
//   }
//   return undefined;
// }
// const BASE_MARGINS = { left: 10, top: 10, bottom: 10, right: 10 };

// const drawFunnel = (stages: { name: string, count: number }[], options: { width: number, height: number, title?: string }) => {
//   const margins = { left: BASE_MARGINS.left, top: BASE_MARGINS.top + (options.title !== undefined? 40 :0)+50, bottom: BASE_MARGINS.bottom, right: BASE_MARGINS.right };
//   const doc = new SVG.Document(options.width,options.height);
//   const layer = doc.layer();

//   if (options.title) {
//     const label = layer.text();
//     label.getStyle().setColour('#000').setSize(32);
//     const textSize = SVG.TextStyle.textSize(options.title, 32, '');
//     label.setPosition(options.width*0.5-textSize.x*0.5, margins.top-50).setLines([options.title]);
//   }
//   let maxCount = Math.max(...stages.map(({ count }) => count));
//   const totalWidth = options.width - (margins.left+margins.right);
//   const stageWidth = (totalWidth/(stages.length+(stages.length-1)*2));
//   const stageHeight = options.height - (margins.top+margins.bottom);

  
//   let prev: number | undefined = undefined;
//   stages.forEach(({name, count},index) => {
//     if (prev === undefined) {
//       prev = count;
//       return;
//     }
//     const path = layer.path();
    
//     const height = count/maxCount*stageHeight;
//     const prevHeight = prev/maxCount*stageHeight;
//     const yMargin = (stageHeight-height)*0.5;
//     const prevYMargin = (stageHeight-prevHeight)*0.5;
//     const radius = 10;
//     prev = count;

//     path.getStyle().setStrokeWidth(1).setStroke('none').setFill('#66b1f348');

//     path.line().addPoint(margins.left+(index-1)*stageWidth*3+stageWidth*0.75,margins.top+prevYMargin+prevHeight).addPoint(margins.left+(index-1)*stageWidth*3+stageWidth*0.75,margins.top+prevYMargin);
//     let p = new Point(margins.left+(index-1)*stageWidth*3+stageWidth*0.75,margins.top+prevYMargin);
//     let p2 = new Point(margins.left+index*stageWidth*3+stageWidth*0.25,margins.top+yMargin);
//     path.curve().addPoint(p,p,p).addPoint(new Point(margins.left+(index-1)*stageWidth*3+stageWidth*1.5,p.y),new Point(margins.left+(index)*stageWidth*3-stageWidth*1.5,p2.y),p2);

//     path.line().addPoint(margins.left+index*stageWidth*3+stageWidth*0.25,margins.top+yMargin).addPoint(margins.left+index*stageWidth*3+stageWidth*0.25,margins.top+yMargin+height);
//     p = new Point(margins.left+index*stageWidth*3+stageWidth*0.25,margins.top+yMargin+height);
//     p2 = new Point(margins.left+(index-1)*stageWidth*3+stageWidth*0.75,margins.top+prevYMargin+prevHeight);
//     path.curve().addPoint(p,p,p).addPoint(new Point(margins.left+(index)*stageWidth*3-stageWidth*1.5,p.y),new Point(margins.left+(index-1)*stageWidth*3+stageWidth*1.5,p2.y),p2);

//     path.close();
    
//   });
//   prev = undefined;
//   stages.forEach(({name, count},index) => {
//     const path = layer.path();
    
//     const height = count/maxCount*stageHeight;
//     const yMargin = (stageHeight-height)*0.5;
//     const radius = 10;
//     prev = count;

//     path.getStyle().setStrokeWidth(1).setStroke('none').setFill('#a00');

//     const label = layer.text();
//     label.getStyle().setColour('#000').setSize(32);
//     const textSize = SVG.TextStyle.textSize(name, 32, '');
//     label.setPosition(margins.left+index*stageWidth*3+stageWidth*0.5-textSize.x*0.35,margins.top+yMargin-textSize.y).setLines([name]);

//     const label2 = layer.text();
//     label2.getStyle().setColour('#000').setSize(32);
//     const textSize2 = SVG.TextStyle.textSize(count.toString(), 32, '');

//     label2.setPosition(margins.left+index*stageWidth*3+stageWidth*0.5-textSize2.x*0.35,margins.top+yMargin+height*0.5-textSize2.y).setLines([count.toString()]);

//     path.line().addPoint(margins.left+index*stageWidth*3,margins.top+yMargin+height-radius).addPoint(margins.left+index*stageWidth*3,margins.top+yMargin+radius);
//     let p = new Point(margins.left+index*stageWidth*3,margins.top+yMargin+radius);
//     path.curve().addPoint(p,p,p).addPoint(new Point(p.x,p.y-radius*0.75),new Point(p.x+radius*0.25,p.y-radius),new Point(margins.left+index*stageWidth*3+radius,margins.top+yMargin));

//     path.line().addPoint(margins.left+index*stageWidth*3+radius,margins.top+yMargin).addPoint(margins.left+index*stageWidth*3+stageWidth-radius,margins.top+yMargin);
//     p = new Point(margins.left+index*stageWidth*3+stageWidth-radius,margins.top+yMargin);
//     path.curve().addPoint(p,p,p).addPoint(new Point(p.x+radius*0.75,p.y),new Point(p.x+radius,p.y+radius*0.25),new Point(margins.left+index*stageWidth*3+stageWidth,margins.top+yMargin+radius));

//     path.line().addPoint(margins.left+index*stageWidth*3+stageWidth,margins.top+yMargin+radius).addPoint(margins.left+index*stageWidth*3+stageWidth,margins.top+yMargin+height-radius);
//     p = new Point(margins.left+index*stageWidth*3+stageWidth,margins.top+yMargin+height-radius);
//     path.curve().addPoint(p,p,p).addPoint(new Point(p.x,p.y+radius*0.75),new Point(p.x-radius*0.25,p.y+radius),new Point(margins.left+index*stageWidth*3+stageWidth-radius,margins.top+yMargin+height));

//     path.line().addPoint(margins.left+index*stageWidth*3+stageWidth-radius,margins.top+yMargin+height).addPoint(margins.left+index*stageWidth*3+radius,margins.top+yMargin+height);
//     p = new Point(margins.left+index*stageWidth*3+radius,margins.top+yMargin+height);
//     path.curve().addPoint(p,p,p).addPoint(new Point(p.x-radius*0.75,p.y),new Point(p.x-radius,p.y-radius*0.25),new Point(margins.left+index*stageWidth*3,margins.top+yMargin+height-radius));

//     path.close();

//   });

//   return doc;
// }

// export const Funnel = (props: Props) => {
//   const [data, setData] = useState<ReportState>(DataCache.getCachedValue<ReportState>('report.data') || {
//     lastReportCall: 0,
//     lastReportDataCall: 0,
//     reportData: {},
//     reports: {},
//   });
//   const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
//   const now = Date.now()*0.001;

//   const [state, setState] = useState<{ reportDataId?: string | undefined, dateRange: { start: number, end: number } }>({ dateRange: { start: now-3600*24*60, end: now }});

//   const { loading: itemsLoading, items } = useItemCollections({
//     festivals: FestivalBinding.getBinding(props.context),
//   });
  
//   const setReports = useCallback((reports: ReportModel[], time: number) => {
//     data.reports = {};
//     reports.forEach((report) => {
//       data.reports[report.id] = report;
//     });
//     data.lastReportCall = time;
//     DataCache.setCachedValue('report.data', data);
//     setData(data);
//     delete loading['reports'];
//     setLoading(loading);
//   }, [data, loading]);

//   const setReportData = useCallback((reports: ReportDataModel[], time: number) => {
//     const existingIds: { [key: string]: boolean } = {};
//     Object.keys(data.reportData).forEach((k) => {
//       existingIds[k] = false;
//     })
//     reports.forEach((report) => {
//       if (data.reportData[report.id] === undefined) {
//         data.reportData[report.id] = {
//           data: [],
//           lastDataUpdate: 0,
//           reportData: report,
//         }
//       }
//       existingIds[report.id] = true;
//       data.reportData[report.id].reportData = report;
//     });
//     Object.entries(existingIds).forEach(([k, v]) => {
//       if (!v) {
//         delete data.reportData[k];
//       }
//     });
//     data.lastReportDataCall = time;
//     DataCache.setCachedValue('report.data', data);
//     setData(data);
//     delete loading['reportData'];
//     setLoading(loading);
//   }, [data, loading]);

//   const updateReportData = useCallback((id: string, values: DataColumValueModel[][], time: number) => {
//     if (data.reportData[id] === undefined) {
//       return;
//     }
//     data.reportData[id].data = values.sort((a,b) => (a[0] as number) - (b[0] as number));
//     data.reportData[id].lastDataUpdate = time;
//     DataCache.setCachedValue('report.data', data);
//     setData(data);
//     delete loading[id];
//     setLoading(loading);
//   }, [data, loading]);

//   let load = Object.values(loading).filter((v) => v).length > 0;

//   if (data.lastReportCall < now-3600 && loading['reports'] !== true) {
//     load = true;
//     setLoading({ ...loading, 'reports': true });
//     DataCache.makeCachedRequest('report.list.all', {})
//       .then((reports) => {
//         if (reports.result === 'success') {
//           setReports(reports.items, now);
//         } 
//       });
//   }
//   if (data.lastReportDataCall < now-3600 && loading['reportData'] !== true) {
//     load = true;
//     setLoading({ ...loading, 'reportData': true });
//     DataCache.makeCachedRequest('reportData.list.all', {})
//       .then((reports) => {
//         if (reports.result === 'success') {
//           setReportData(reports.items, now);
//         } 
//       });
//   }

//   if (load || itemsLoading) {
//     return <HeaderPage>
//     <Block.Block alternate={true}>
//       <Standard.StandardContent>
//         <div style={{ padding: 15, margin: 15, marginLeft: 'auto', marginRight: 'auto', flex: 1, maxWidth: 600 }} >
//           LOADING
//         </div>
//       </Standard.StandardContent>
//     </Block.Block>
//   </HeaderPage>
//   }
//   let svg: JSX.Element | undefined = undefined;
//   let funnel: JSX.Element | undefined = undefined;
//   if (state.reportDataId) {
//     const reportData = data.reportData[state.reportDataId];
//     if (reportData === undefined) {
//       setState({ ...state, reportDataId: undefined });
//     } else if (reportData.lastDataUpdate < now-3600 && loading[state.reportDataId] !== true) {
//       const id = state.reportDataId;
//       const read = (continueToken: string | undefined, data: DataColumValueModel[][]) => {
//         DataCache.makeCachedRequest('reportData.read', {
//           reportDataId: id,
//           continueToken: continueToken,
//           start: undefined,
//         })
//           .then((res) => {
//             if (res.result === 'success') {
//               if (res.continueToken) {
//                 read(res.continueToken, [...data,...res.data])
//               } else {
//                 updateReportData(id, [...data,...res.data], now);
//               }
//             }
//           });
//       }
//       read(undefined, []);
//     } else if (loading[state.reportDataId] !== true) {
//       let start = now-3600*24*90;
//       let end = now;
//       let s = new Date(start*1000);
//       while (s.getUTCHours() !== 14) {
//         s = new Date(s.getFullYear(), s.getMonth(), s.getDate(), s.getHours()-1);
//       }
//       start = s.getTime()*0.001;
//       s = new Date(end*1000);
//       while (s.getUTCHours() !== 8) {
//         s = new Date(s.getFullYear(), s.getMonth(), s.getDate(), s.getHours()+1);
//       }
//       end = s.getTime()*0.001;
//       const step = 3600*24*7;
//       const dataSet = new Data.DataSet();
//       dataSet.labels = [];
//       let time = start;
//       while(time<end) {
//         const date = new Date(time*1000);
//         dataSet.labels.push(`${date.getDate().toString().padStart(2,'0')}/${(date.getMonth()+1).toString().padStart(2,'0')}/${date.getFullYear()}`)
//         time += step;
//       }
      
//       Object.values(reportData.reportData.dataColumns || {}).sort((a,b) => (a.ordinal || 0) - (b.ordinal || 0)).forEach((column: any) => {
//         const series: (number | undefined)[] = [];
//         const subSeries: { [key: string]: (number | undefined)[] } = {};
//         let time = start;
//         let index = 0;
//         let point = reportData.data[index];
//         while(time < end) {
//           const et = time+step;
//           let total: DataColumValueModel | undefined = undefined;
//           while (point !== undefined && (point[0] as number) < et) {
//             const value = point[column.index+1];
//             total = addValue(value, total, column);
//             index += 1;
//             point = reportData.data[index];
//           }
//           const l = series.length;
//           if (column.subStructure !== undefined && typeof total === 'object' && (column.subStructure.display === 'display' || column.subStructure.display === undefined || true)) {
//             Object.entries(total as { [key: string]: DataColumValueModel | undefined }).forEach(([k,v]) => {
//               const n = column.subStructure.name.replace('${.}', k);
//               let sub = subSeries[n];
//               if (sub === undefined) {
//                 sub = [];
//                 for(let i = 0; i < l; i ++) {
//                   sub.push(0);
//                 }
//                 subSeries[n] = sub;
//               }
//               const sv = getValue(v, column.subStructure);
//               sub.push(sv?sv:0);
//             })
//           }
//           const v = getValue(total, column);
//           series.push(v?v:0);
//           Object.values(subSeries).forEach(sub => {
//             if (sub.length === l) {
//               sub.push(0);
//             }
//           });
//           time += step;
//         }
//         // if (column.display === 'display' || column.display === undefined) {
//         //   dataSet.series[column.name] = series;
//         // }
//         dataSet.series[column.name] = series;
//         Object.entries(subSeries).forEach(([k,v]) => {
//           dataSet.series[k] = v;
//         });
//       })
//       const name = reportData.reportData.report?  data.reports[reportData.reportData.report].name: '';
//       const f = reportData.reportData.key && reportData.reportData.key['festivalId']? items.festivals.find(f => f.id === reportData.reportData.key['festivalId']): undefined;
//       const festival = f !== undefined && f.name !== undefined? ` (${f.name})`: '';

//       const doc = Graph.line(dataSet, { width: 2000, height: 1000 }, { title: `${name}${festival}`, labelFormatter: v => v.toString()});
//       svg = doc.processDoc<JSX.Element, JSX.Element>({
//         svg: (props, children) => <svg {...props}>{children}</svg>,
//         layer: (props, children) => <g>{children}</g>,
//         line: (props: any, value) => <tspan {...props}>{value}</tspan>,
//         text: (props, children) => <text {...props}>{children}</text>,
//         path: (props, d) => <path {...props} d={d}></path>,
//       })
      
//     }
//     const counts = [0,0,0]
//     Object.values(reportData.reportData.dataColumns || {}).sort((a,b) => (a.ordinal || 0) - (b.ordinal || 0)).forEach((column) => {
//       let i = -1;
//       if (column.name === 'Accounts Created') {
//         i = 0;
//       } else if (column.name === 'Logins') {
//         i = 1;
//       } else if (column.name === 'Total Games Played') {
//         i = 2;
//       }
//       if (i !== -1) {
//         let total: DataColumValueModel | undefined = undefined;
//         reportData.data.forEach(sample => {
//           total = addValue(sample[(column.index || 0)+1], total, column);
//         });
//         counts[i] = getValue(total, column) || 0;
//         console.log(column, i, counts)
//       }
//     });

//     const doc = drawFunnel([{name:'Accounts Created', count: counts[0]}, {name:'Logins', count: counts[1]}, {name:'Games Played', count: counts[2]}], { width: 2000, height: 1000, title: 'test'});
 
//     funnel = doc.processDoc<JSX.Element, JSX.Element>({
//       svg: (props, children) => <svg {...props}>{children}</svg>,
//       layer: (props, children) => <g>{children}</g>,
//       line: (props: any, value) => <tspan {...props}>{value}</tspan>,
//       text: (props, children) => <text {...props}>{children}</text>,
//       path: (props, d) => <path {...props} d={d}></path>,
//     })
//   }
//   const options: { [key: string]: string } = {};
//   Object.entries(data.reportData).forEach(([k,v]) => {
//     const name = v.reportData.report?  data.reports[v.reportData.report].name: '';
//     const f = v.reportData.key && v.reportData.key['festivalId']? items.festivals.find(f => f.id === v.reportData.key['festivalId']): undefined;
//     const festival = f !== undefined && f.name !== undefined? ` (${f.name})`: '';

//     options[k] = `${name}${festival}`
//   })
//   return <HeaderPage>
//     <div style={{height:10, flexShrink: 0, flexGrow: 0}}></div>
//     <Block.Block alternate={true}>
//       <Standard.StandardContent width={'12'}>
//         <div style={{maxWidth: '66%', alignSelf: 'center'}}>
//           <Dropdown type='dropdown' controller={{ onChange: (value) => setState({ reportDataId: value as string, dateRange: state.dateRange }) }} tmp='123' onChange={(value) => setState({ reportDataId: value as string, dateRange: state.dateRange })} title='Report' options={options}></Dropdown>
//         </div>
//         <div style={{ padding: 15, margin: 15, marginLeft: 'auto', marginRight: 'auto', flex: 1, width:'100%'  }} >
//           {svg?svg: undefined}
//           {funnel?funnel: undefined}
//         </div>
//       </Standard.StandardContent>
//     </Block.Block>
//   </HeaderPage>;
// }

export const Funnel = (props: any) => {

  return <div>NOT HERE</div>
}