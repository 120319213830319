import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Plus } from 'react-bootstrap-icons';
import { Row, Col, Button, Pagination, Form } from 'react-bootstrap';
import { Context, DataBinding, DataObject } from '@audioplay/client';

import { HeaderPage } from '../../components/page/HeaderPage';
import { List, DisplayItem } from '../../components/content/List';
import { ItemModal, FieldDefinition, makeDisplayName, ReferenceCollection } from '../../components/content/ItemModal';
import { Base } from '../../components/model';

const ITEMS_PER_PAGE = 20 as const;

export interface Props<M extends { id: string }, T extends DataObject<M>> {
  modelName: string,
  binding: DataBinding<M, T>,
  newItem?: () => T,
  itemModal?: ((props: { context: Context, item: T, newItem: boolean, closed: () => void }) => React.ReactElement),
  context: Context,
  listFields: FieldDefinition<M,T>[],
  detailFields: FieldDefinition<M,T>[],
  references: ReferenceCollection<M,T>
}

export function AccessValue<T>(item: T, type: DisplayItem<T>) {
  let itemValue = '';
  if (type.accessor === undefined) {
    itemValue = ''
  } else if (typeof type.accessor === 'string') {
    itemValue = (item as any)[type.accessor];
  } else if (typeof type.accessor === 'function') {
    itemValue = type.accessor(item);
  } else {
    let v = item as any;
    type.accessor.forEach(k => {
      v = v === undefined? undefined: v[k];
    })
    itemValue = v;
  }
  return itemValue;
}

export function ListPage<M extends { id: string }, T extends DataObject<M>>(props: Props<M,T>) {
  // const [items, setItems] = useState<T[]>([]);
  const { loading, items } = Base.useItemCollection(props.binding);
  const [filteredItems, setFilteredItems] = useState<T[]>(items);
  const [item, setItem] = useState<{ item: T, newItem: boolean } | undefined>(undefined);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<{ [key: string]: 'ascending' | 'descending' | undefined }>({});
  const [search, setSearch] = useState<string>('');
  let pageCount = Math.ceil(filteredItems.length/ITEMS_PER_PAGE);
  if (pageCount === 0) {
    pageCount = 1;
  }
  
  // const [loading, setLoading] = useState(true);

  const location = useLocation()
  const params = useParams();
  const paramId = params['itemId'];
  const itemId = location.state? location.state.itemId: paramId;

  const generateFilteredItems = (items: T[], search: string | undefined, sort: { [key: string]: 'ascending' | 'descending' | undefined }) => {
    const fi = items.filter(i => {
      const s = search;
      if (s === undefined || s === '') {
        return true;
      }
      let match = false;
      props.listFields.forEach(k => {
        if (match) {
          return;
        }
        const key = AccessValue(i, {
          name: '',
          accessor: k.key,
        });
        if (key) {
          match = key.toString().toLowerCase().includes(s.toLowerCase());
        }
      });
      return match;
    })
    if (Object.keys(sort).length > 0) {
      const [name, state] = Object.entries(sort)[0];
      const f = props.listFields.find(k => {
        const key = k.name === undefined? makeDisplayName(typeof k.key === 'function'? '': Array.isArray(k.key)?k.key[k.key.length-1]:k.key): k.name;
        return key === name;
      });
      if (f) {
        fi.sort((a,b) => {
          const va = AccessValue(a, {
            name: '',
            accessor: f.key,
          });
          const vb = AccessValue(b, {
            name: '',
            accessor: f.key,
          });
          if (va < vb) {
            return state === 'ascending'? 1: -1
          } else if (va > vb) {
            return state === 'ascending'? -1: 1
          } 
          return 0;
        });
      }
    }
    setFilteredItems(fi);
  }

  useEffect(() => {
    const item = items.find(i => i.id === itemId);
    setItem(item?{ item, newItem: false }:undefined);
    generateFilteredItems(items, search, sort);
  }, [itemId, items]);

  useEffect(() => {
    if (page+1 > pageCount) {
      setPage(pageCount-1);
    }
  }, [pageCount]);
  const searchChanged = useCallback((e: any) => {
    generateFilteredItems(items, e.target.value, sort);
    setSearch(e.target.value || '');
  },[items, sort]);
  const newItem = props.newItem;
  const render = props.itemModal? props.itemModal: (p: { context: Context, item: T, closed: () => void }) => <ItemModal fields={props.detailFields} references={props.references} item={p.item} closed={p.closed} context={p.context} />
  return <HeaderPage alignContent='top'>
    <Row style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 30, paddingRight: 30, justifyContent: 'space-between', display: 'flex', flexDirection: 'row',   }}>
      <Col style={{flexGrow:0, fontWeight: 'bold', fontSize: 24}}>{props.modelName}</Col>
      <div style={{flexGrow:1}}></div>
      <Col style={{flexGrow:0 }}><Form.Control style={{width: 200}} type='text' placeholder='Search' value={search} onChange={searchChanged}></Form.Control></Col>
      <div style={{flexGrow:0, width: 30}}></div>
      {newItem?<Button onClick={() => {
        setItem({ item: newItem(), newItem: true })
      }}><Plus size={24}></Plus></Button>:undefined}
    </Row>
    <List
      items={filteredItems.slice(page*ITEMS_PER_PAGE,(page+1)*ITEMS_PER_PAGE)}
      loading={loading} 
      select={(item) => {setItem({ item, newItem: false })}}
      display={props.listFields.map(k => {
        const key = k.name === undefined? makeDisplayName(typeof k.key === 'function'? '': Array.isArray(k.key)?k.key[k.key.length-1]:k.key): k.name;
        return {
          name: key,
          accessor: k.key,
          type: k.type === 'date'? 'date': 'string',
          sort: sort[key]
        }
      })}
      sort={(name, state) => {
        const newState = state === 'descending'? 'ascending': 'descending';
        setSort({ [name]: newState });
        if (newState !== undefined) {
          const f = props.listFields.find(k => {
            const key = k.name === undefined? makeDisplayName(typeof k.key === 'function'? '': Array.isArray(k.key)?k.key[k.key.length-1]:k.key): k.name;
            return key === name;
          });
          if (f) {
            filteredItems.sort((a,b) => {
              const va = AccessValue(a, {
                name: '',
                accessor: f.key,
              });
              const vb = AccessValue(b, {
                name: '',
                accessor: f.key,
              });
              if (va < vb) {
                return newState === 'ascending'? 1: -1
              } else if (va > vb) {
                return newState === 'ascending'? -1: 1
              } 
              return 0;
            });
            setFilteredItems(filteredItems);
          }
        }
      }}
    />
    {pageCount === 1? undefined:<Pagination key='pages' style={{margin: '1rem'}}>
      {page-1>0?<Pagination.Item onClick={() => setPage(0)}>1</Pagination.Item>:undefined}
      {page-2>0?<Pagination.Ellipsis disabled={true} />:undefined}
      {page>0?<Pagination.Item onClick={() => setPage(page-1)}>{page}</Pagination.Item>:undefined}
      <Pagination.Item active={true}>{page+1}</Pagination.Item>
      {page+1<pageCount?<Pagination.Item onClick={() => setPage(page+1)}>{page+2}</Pagination.Item>:undefined}
      {page+3<pageCount?<Pagination.Ellipsis />:undefined}
      {page+2<pageCount?<Pagination.Item onClick={() => setPage(pageCount-1)}>{pageCount}</Pagination.Item>:undefined}
    </Pagination>}
    {item?render({ context: props.context, item: item.item, newItem: item.newItem, closed: () => { if (item) { item.item.reset(); } setItem(undefined)} }):undefined}
  </HeaderPage>;
};
