import React, { Component, useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeRequest, API } from '@audioplay/client';

import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

import { Modal, Button } from 'react-bootstrap';

import { login } from '../../state/action/user';
import { state } from '../../state/reducer/user';


export interface Props extends HeaderProps {

};

const mapOrgName = (id: string) => {
  return {
    'library': 'Community Library',
    'school': 'School/School Library',
    'vacation.care': 'Vacation Care Provider',
    'local.government': 'Local Government',
    'arts.small': 'Small Arts Organisation',
    'arts.medium': 'Medium Arts Organisation',
    'arts.large': 'Large Arts Organisation',
    'other': 'Other',
  }[id];
}

const festivalContentIds: { [key: string]: string | undefined } = {
  'bamboo.bandits': 'Pandas4Player.FestivalPreview.Episode1',
  'sun.runners': 'SunRunners4Player.Preview.Episode1',
  'the.turners': 'TheTurners4Player.FestivalPreview.Episode1'
};

export const PresenterPack = (props: Props) => {
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [organisationPrompt, setOrganisationPrompt] = useState<{ name: string, existing: string, new: string, resolve: (value: string) => void } | undefined>(undefined);
  
  return <HeaderPage>
    <Block.Block alternate={true}>
      <Standard.StandardContent>
        {organisationPrompt === undefined? undefined: <Modal key={'popup'} show={true}>
          <Modal.Header closeButton={false}>
            <Modal.Title>Select Organisation</Modal.Title>
          </Modal.Header>
            <Modal.Body>
            <p>{`${organisationPrompt.name} has already indicated their Organisation Type is ${mapOrgName(organisationPrompt.existing)}. Which organisation would you like to use?`}</p>
          </Modal.Body>
          <Modal.Footer style={{justifyContent: 'center'}}>
            <Button variant="audioplay-secondary" onClick={() => {organisationPrompt.resolve(organisationPrompt.existing)}}>{mapOrgName(organisationPrompt.existing)}</Button>
            <Button variant="audioplay-secondary" onClick={() => {organisationPrompt.resolve(organisationPrompt.new)}}>{mapOrgName(organisationPrompt.new)}</Button>
          </Modal.Footer >
        </Modal>}

        {message === undefined? undefined: <Modal key={'popup'} show={true} onHide={() => {setMessage(undefined)}}>
          <Modal.Header closeButton={false}>
            <Modal.Title>{message}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="audioplay-secondary" onClick={() => {setMessage(undefined)}}>OK</Button>
          </Modal.Footer>
        </Modal>}
        
        <div style={{ padding: 15, margin: 15, marginLeft: 'auto', marginRight: 'auto', flex: 1, maxWidth: 600 }} >
          <Form
            submitHandler={(action, state) => {
              setMessage(undefined);
              if (action === 'submit') {
                const email = state['email'].value === undefined? undefined: state['email'].value.toString().toLowerCase();
                const name = state['name'].value === undefined? undefined: state['name'].value.toString();
                const content = state['content'].value === undefined? undefined: state['content'].value.toString();
                const organisation = state['organisation'].value === undefined? undefined: state['organisation'].value.toString();
                const tags = state['tags'].value === undefined? undefined: state['tags'].value === true;
                
                if (email === undefined) {
                  const errors: { [key: string]: string } = {};
                  errors['email'] = '* required';
                  return Promise.resolve(errors);
                }
                if (!/^[^@\s]+@[^\.@\s]+\.[^@\s]+$/.test(email)) {
                  const errors: { [key: string]: string } = {};
                  errors['email'] = '* email address not valid';
                  return Promise.resolve(errors);
                }

                const access = state['preview'].value === true;

                return makeRequest('user.get', {
                  email,
                }, { bypassCache: true })
                  .then((resp) => {
                    if (resp.result === 'success') {
                      let org = Promise.resolve(organisation);
                      if (resp.featureFlags && resp.featureFlags['web.presenter'] && resp.featureFlags['web.presenter'].data && resp.featureFlags['web.presenter'].data['organisation'] !== organisation) {
                        const existing = resp.featureFlags['web.presenter'].data['organisation'];
                        org = new Promise<string>((res) => {
                          setOrganisationPrompt({ name: name || email || '', existing, new: organisation || '', resolve: res });
                        }).then((r) => {
                          setOrganisationPrompt(undefined);
                          return r;
                        })
                      }
                      return org
                        .then((org) => makeRequest('user.update', {
                          id: resp.id,
                          featureFlags: {
                            ...(resp.featureFlags || {}),
                            'web.presenter': {
                              added: ((resp.featureFlags || {})['web.presenter'] || {}).added || Date.now()*0.001,
                              data: {
                                content,
                                organisation: org,
                                deliveryMode: (((resp.featureFlags || {})['web.presenter'] || {}).data || {}).deliveryMode  || 'unknown',
                              }
                            }
                          }
                        }))
                        .then(() => resp.id);
                    } else {
                      return makeRequest('user.create', {
                        email,
                        name,
                        featureFlags: {
                          'web.presenter': {
                            added: Date.now()*0.001,
                            data: {
                              content,
                              organisation,
                              deliveryMode: 'unknown',
                            }
                          }
                        }
                      })
                        .then((resp) => {
                          if (resp.result === 'success') {
                            return resp.id;
                          }
                          return Promise.reject();
                        });
                    }
                  })
                  .then((userId) => {
                    const product = festivalContentIds[content || ''];
                    if (access && product !== undefined) {
                      return makeRequest('account.add.festival', {
                        userId: userId,
                        festivalId: '9c6cbfd0-874a-4c1f-af28-05656ad7cbaf',
                        end: Date.now()*0.001+3600*24*31,
                        episodeIds: [ { product: product }],
                        mergeEpisodes: true,
                      })
                        .then(() => userId);
                    }
                    return userId;
                  })
                  .then((userId) => {
                    if (tags) {
                      return makeRequest('user.add.tags', {
                        userId: userId,
                        addTags: ['B2B-InquireyForm','B2B-InquireyForm-PresenterPack'],
                      })
                        .then((res) => {
                          return res.result === 'success'? 'success': 'tags';
                        });
                    } else {
                      return Promise.resolve('success');
                    }
                  }, () => 'user')
                  .then((response) => {
                    if (response === 'success') {
                      setMessage('User Updated Successfully')
                    } else if (response === 'tags') {
                      const errors: { [key: string]: string } = {};
                      errors['tags'] = '* could not add tags to user';
                      errors['submit'] = '* could not add tags to user';
                      return errors;
                    } else if (response === 'user') {
                      const errors: { [key: string]: string } = {};
                      errors['email'] = '* could not apply to user';
                      errors['submit'] = '* could not apply to user';
                      return errors;
                    }
                    return undefined;
                  });
              }
              return Promise.resolve(undefined);
            }}
            template={[
              {
                type: 'section',
                title: 'Presenter Pack Access',
                items: [],
              },
              {
                type: 'section',
                items: [
                  {
                    title: 'Name',
                    id: 'name',
                    type: 'text',
                    value: '',
                    optional: true,
                  },
                  {
                    title: 'Email',
                    id: 'email',
                    type: 'text',
                    value: '',
                  },
                  {
                    title: 'Story',
                    id: 'content',
                    type: 'dropdown',
                    value: '',
                    options: {
                      'the.turners': {
                        value: 'The Turners'
                      },
                      'sun.runners': {
                        value: 'Sun Runners'
                      },
                      'bamboo.bandits': {
                        value: 'Bamboo Bandits'
                      },
                    },
                    onChange: (value, controller, information) => {
                      controller.setHidden('preview', !(typeof value === 'string' && festivalContentIds[value || ''] !== undefined));
                    },
                  },
                  {
                    title: 'Organisation Type',
                    id: 'organisation',
                    type: 'dropdown',
                    value: '',
                    options: {
                      'library': {
                        value: 'Community Library (Small)'
                      },
                      'school': {
                        value: 'School/School Library (Small)'
                      },
                      'vacation.care': {
                        value: 'Vacation Care Provider (Small)',
                      },
                      'local.government': {
                        value: 'Local Government (Medium)',
                      },
                      'arts.small': {
                        value: 'Small Arts Organisation',
                      },
                      'arts.medium': {
                        value: 'Medium Arts Organisation',
                      },
                      'arts.large': {
                        value: 'Large Arts Organisation',
                      },
                      'other': {
                        value: 'Other (Large)'
                      }
                    },
                  },
                  {
                    title: 'Add Tags (Mailchimp Emails: B2B-InquireyForm)',
                    id: 'tags',
                    type: 'checkbox',
                    value: false,
                  },
                  {
                    title: 'In App Preview',
                    id: 'preview',
                    type: 'checkbox',
                    value: false,
                    display: {
                      hidden: true,
                    }
                  },
                ],
              },
              {
                type: 'section',
                items: [
                  {
                    type: 'row',
                    display: {
                      align: 'centre',
                      style: { marginTop: 10 }
                    },
                    items: [      
                      {
                        id: 'submit',
                        type: 'button',
                        title: 'Submit',
                        value: 'submit',
                        onClick: 'submit',
                      }
                    ]
                  },
                ],
              }
            ]} />
            {message?<div>{message}</div>:undefined}
        </div>
      </Standard.StandardContent>
    </Block.Block>
  </HeaderPage>;
}