import { useRef, useState } from 'react';
import { ActivationDataModel, Campaign, Festival } from '@audioplay/client';
import { BoxArrowUpRight, ChevronUp, ChevronDown, DashSquareFill, PlusSquareFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

import { FormBinding } from './FormBinding';
import { Form, Control, Section, Row, FormController, ControlOptions, Custom } from '../../components/form';
import { episodeInformation } from './Data';

export const useActivationFormGenerator = () => {
  const [state, setState] = useState<{
    festivalAdjustments: {
      [key: string]: {
        state: 'add' | 'remove',
      }
    },
    campaignAdjustments: {
      [key: string]: {
        state: 'add' | 'remove',
      }
    }
  }>({
    festivalAdjustments: {},
    campaignAdjustments: {},
  });

  const stateRef = useRef(state);
  stateRef.current = state;

  const navigate = useNavigate();

  function builder<T>(
    data: ActivationDataModel,
    campaigns: { [key: string]: Campaign },
    festivals: { [key: string]: Festival },
    binding: FormBinding<T>,
    state: {
      toggle: (section: 'access' | 'campaign' | 'festival', controller: FormController) => void,
      onToggle: (section: 'access' | 'campaign' | 'festival', key: string, toggle: (active: boolean, controller: FormController) => void) => void,
      access: boolean,
      campaigns: boolean,
      festivals: boolean,
    },
    options: {
      access: boolean,
      campaigns: boolean,
      festivals: boolean,
    }
  ): Section[] {
    const result: Section[] = [];
    if (options.access) {
      state.onToggle('access', 'activationData.access', (active, controller) => {
        controller.setState('access.title.indicator', { active } as any)
        const parent = controller.getParent('access.title.row');
        if (parent) {
          controller.getChildren(parent).forEach((i) => {
            if (i !== 'access.title.row') {
              controller.setHidden(i, !active);
            }
          })
        }
      });
      result.push({
        id: 'access.section',
        type: 'section',
        items: [{
          type: 'row',
          id: 'access.title.row',
          onClick: (controller) => {
            state.toggle('access', controller);
          },
          display: {
            style: {
              justifyContent: 'center',
              alignItems: 'center',
            }
          },
          items: [
            {
              type: 'label',
              value: 'Episodes',
              display: {
                classNames: 'title text'
              }
            },
            {
              id: 'access.title.indicator',
              type: 'custom',
              render: (props: any) => {
                return props.active? <ChevronDown size={30}></ChevronDown>: <ChevronUp size={30}></ChevronUp>;
              },
              active: state.access
            } as any,
          ],
        },
        {
          id: 'access.label.row',
          type: 'row',
          display: {
            hidden: true,
          },
          items:[
            {
              type: 'label',
              value: '',
              display: {
                style: {
                  width: '100px',
                }
              }
            },
            {
              type: 'label',
              value: 'Episode',
              display: {
                style: {
                  width: '250px',
                }
              }
            },
            {
              type: 'label',
              value: 'Access',
              display: {
                style: {
                  width: '65px',
                }
              }
            },
            {
              type: 'label',
              value: 'Permanent',
              display: {
                style: {
                  width: '85px',
                }
              }
            },
            {
              type: 'label',
              value: 'Access End',
              display: {
                style: {
                  width: '250px',
                }
              }
            },
            {
              type: 'label',
              value: 'Access Length',
              display: {
                style: {
                  width: '200px',
                }
              }
            },
          ]
        },
        ...episodeInformation.map(i => {
          return binding.bindCollection({
            type: 'row',
            id: `access.row.${i.productName}`,
            display: {
              hidden: !state.access,
            },
            items: [
              {
                type: 'img',
                value: i.img,
                display: {
                  disabled: true,
                  props: {
                    imageWidth: '100px',
                    imageHeight: '100px'
                  }
                }
              },
              {
                type: 'label',
                value: i.title,
                display: {
                  style: {
                    width: '250px',
                  }
                }
              },
              {
                type: 'checkbox',
                value: '#[access]',
                display: {
                  style: {
                    width: '65px',
                  }
                }
              },
              {
                type: 'checkbox',
                value: '#[permanent]',
                optional: true,
                onChange: (v, controller, info) => {
                  if (typeof v === 'boolean') {
                    controller.setHidden(controller.getChildren(info.parentId)[4], v);
                    controller.setHidden(controller.getChildren(info.parentId)[5], v);
                  } else {
                    controller.setHidden(controller.getChildren(info.parentId)[4], false);
                    controller.setHidden(controller.getChildren(info.parentId)[5], false);
                  }
                },
                display: {
                  style: {
                    width: '85px',
                  }
                }
              },
              {
                type: 'datetime',
                value: '#[accessEnd]',
                optional: true,
                display: {
                  hidden: '#[permanent]' as any,
                  group: {
                    style: {
                      width: '250px',
                    }
                  }
                }
              },
              {
                type: 'timespan',
                value: '#[accessLength]',
                optional: true,
                display: {
                  hidden: '#[permanent]' as any,
                  group: {
                    style: {
                      width: '200px',
                    }
                  }
                }
              }
            ]
          }, 'access.'+i.productName, () => {
            let a;
            a = (data.access || []).find(x => x.episode === i.productName);
            return {
              access: a !== undefined,
              permanent: (a !== undefined? a.permanent: undefined),
              accessEnd: (a !== undefined? a.accessEnd: undefined),
              accessLength: (a !== undefined? a.accessLength: undefined),
            };
          }, (values) => {
            if (data.access === undefined || data.access === null) {
              data.access = [];
            }
            const errors: { [key: string]: string } = {};
            const now = Date.now()*0.001;
            const existing = data.access.findIndex(e => e.episode === i.productName);
            const item: {
              episode?: string | undefined,
              productId?: string | string[] | undefined,
              accessLength?: number | undefined,
              accessEnd?: number | undefined,
              permanent?: boolean | undefined
            } = existing === -1? {
              episode: i.productName,
              productId: i.productIds,
            }: data.access[existing];
            if (values.permanent) {
              item.permanent = true;
            } else if (typeof values.accessLength === 'number') {
              item.accessLength = values.accessLength;
            } else if (typeof values.accessEnd === 'number') {
              item.accessEnd = values.accessEnd;
            }
            if (values.access) {
              if (values.permanent !== true && typeof values.accessLength !== 'number' && typeof values.accessEnd !== 'number') {
                errors[`access.row.${i.productName}`] = '* Access Required. One of permanent, access end or access length is required.';
              }
              if (existing !== -1) {
                data.access[existing] = item;
              } else {
                data.access.push(item);
              }
            } else if (existing !== -1) {
              data.access.splice(existing, 1)
            }
            return Object.keys(errors).length === 0? undefined: errors;
          });
        })]
      })
    }

    if (options.festivals) {

      const adjustFestivals = (festivalId: string, action: 'add' | 'remove') => {
        if (stateRef.current.festivalAdjustments[festivalId]) {
          if (stateRef.current.festivalAdjustments[festivalId].state !== action) {
            delete stateRef.current.festivalAdjustments[festivalId];
            setState({ ...stateRef.current, festivalAdjustments: stateRef.current.festivalAdjustments });
          }
        } else {
          setState({ ...stateRef.current, festivalAdjustments: { ...stateRef.current.festivalAdjustments, [festivalId]:  { state: action }}});
        }
      };
    
      const setFestivalAddFilter = (festivalId: string, action: 'add' | 'remove', controller: FormController) => {
        const options: ControlOptions = {
          '': { default: true, disabled: true, value: '' },
        }
        let noOptions = true;
        Object.values(festivals).filter(f => {
          if (f.id === festivalId) {
            return action === 'remove';
          }
          if (stateRef.current.festivalAdjustments[f.id]) {
            return stateRef.current.festivalAdjustments[f.id].state === 'remove';
          }
          return (data.festivals || []).find(i => i.festivalId === f.id) === undefined;
        }).forEach((f) => {
          noOptions = false;
          options[f.id] = f.name || f.id;
        });
        controller.setState('festival.add.select', { options, value: undefined, display: { hidden: noOptions } });
        controller.setState('festival.add.button', { display: { hidden: noOptions } });
      };

      state.onToggle('festival', 'activationData.festival', (active, controller) => {
        controller.setState('festival.title.indicator', { active } as any)
        const parent = controller.getParent('festival.title.row');
        if (parent) {
          controller.getChildren(parent).forEach((i) => {
            if (i !== 'festival.title.row') {
              controller.setHidden(i, !active);
            }
          })
        }
      });

      binding.bindingAction('festival.submit', () => {
        Object.entries(stateRef.current.festivalAdjustments).forEach(([id, state]) => {
            if (state.state === 'add') {
              if (data.festivals === undefined || data.festivals === null) {
                data.festivals = [];
              }
              if (data.festivals.find(i => i.festivalId === id) === undefined) {
                data.festivals.push({ festivalId: id });
              }
            } else if (state.state === 'remove') {
              if (data.festivals !== undefined && data.festivals !== null) {
                const i = data.festivals.findIndex(i => i.festivalId === id);
                if (i !== -1){
                  data.festivals.splice(i,1);
                }
              }
            }
        })
        return undefined;
      });

      const options: ControlOptions = {
        '': { default: true, disabled: true, value: '' },
      }
      let noOptions = true;
      Object.values(festivals).filter(f => {
        if (stateRef.current.festivalAdjustments[f.id]) {
          return stateRef.current.festivalAdjustments[f.id].state === 'remove';
        }
        return (data.festivals || []).find(i => i.festivalId === f.id) === undefined;
      }).forEach((f) => {
        noOptions = false;
        options[f.id] = f.name || f.id;
      });

      result.push({
        id: 'festival.section',
        type: 'section',
        items: [{
          type: 'row',
          id: 'festival.title.row',
          onClick: (controller) => {
            state.toggle('festival', controller);
          },
          display: {
            style: {
              justifyContent: 'center',
              alignItems: 'center',
            }
          },
          items: [
            {
              type: 'label',
              value: 'Festivals',
              display: {
                classNames: 'title text'
              }
            },
            {
              id: 'festival.title.indicator',
              type: 'custom',
              render: (props: any) => {
                return props.active? <ChevronDown size={30}></ChevronDown>: <ChevronUp size={30}></ChevronUp>;
              },
              active: state.festivals
            } as any,
          ],
        },
        {
          id: 'episode.label.row',
          type: 'row',
          display: {
            hidden: true,
          },
          items:[
            {
              type: 'label',
              value: '',
              display: {
                style: {
                  width: '100px',
                }
              }
            },
            {
              type: 'label',
              value: 'Festival',
              display: {
                style: {
                  width: '250px',
                }
              }
            },
            {
              type: 'label',
              value: 'Start',
              display: {
                style: {
                  width: '250px',
                }
              }
            },
            {
              type: 'label',
              value: 'End',
              display: {
                style: {
                  width: '250px',
                }
              }
            },
          ]
        },
        {
          id: 'festival.add.row',
          type: 'row',
          display: {
            hidden: true,
          },
          items:[
            {
              id: 'festival.add.select',
              type: 'dropdown',
              options,
              optional: true,
              display: {
                hidden: noOptions
              }
            },
            {
              id: 'festival.add.button',
              type: 'custom',
              render: (p) => <PlusSquareFill onClick={p.click} size={24} color='blue' style={{alignSelf: 'center'}}/>,
              onClick: (controller, information) => {
                const v = controller.getValue('festival.add.select');
                if (v) {
                  adjustFestivals(v.toString(), 'add');
                  setFestivalAddFilter(v.toString(), 'add', controller);
                }
              },
              display: {
                hidden: noOptions
              }
            },
          ]
        },
        ...[
          ...Object.values(data.festivals || {})
            .map((data) => {
              const festival = festivals[data.festivalId || ''];
              return { festival, id: data.festivalId }
            })
            .filter(({ festival, id }) => {
              if (festival && stateRef.current.festivalAdjustments[festival.id] && stateRef.current.festivalAdjustments[festival.id].state === 'remove') {
                return false;
              }
              return festival !== undefined;
            }),
          ...Object.entries(stateRef.current.festivalAdjustments)
            .filter(([id, data]) => data.state !== 'remove')
            .map(([id, data]) => {
              const festival = festivals[id];
              return { festival, id }
            })
            .filter(({ festival, id }) => {
              return festival !== undefined;
            })
        ]
          .map(({festival}) => {
            const images = (festival.appConfiguration?.images) || {};
            let img: string | undefined = undefined;
            ['Icon', 'Logo'].forEach(n => {
              const i = images[n];
              if (i !== undefined && img === undefined) {
                img = i.file;
              }
            })
            if (img === undefined && Object.values(images).length > 0 && Object.values(images)[0]) {
              img = images[0].file;
            }
            return {
              id: festival.id,
              type: 'row',
              display: {
                hidden: !state.festivals,
              },
              items: [
                {
                  type: 'img',
                  value: img && !img.startsWith('http')?process.env.DEFAULT_ASSET_URL+'/'+img:img,
                  display: {
                    disabled: true,
                    props: {
                      imageWidth: '100px',
                      imageHeight: '100px'
                    }
                  }
                },
                {
                  type: 'label',
                  value: festival.name,
                  display: {
                    style: {
                      width: '250px',
                    }
                  }
                },
                {
                  type: 'datetime',
                  value: festival.start,
                  display: {
                    disabled: true,
                    group: {
                      style: {
                        width: '250px',
                      }
                    }
                  }
                },
                {
                  type: 'datetime',
                  value: festival.end,
                  display: {
                    disabled: true,
                    group: {
                      style: {
                        width: '250px',
                      }
                    }
                  }
                },
                {
                  type: 'custom',
                  render: (p) => <DashSquareFill onClick={p.click} size={24} color='red' style={{alignSelf: 'center'}}/>,
                  onClick: (controller, information) => {
                    adjustFestivals(festival.id, 'remove');
                    setFestivalAddFilter(festival.id, 'remove', controller)
                  },
                },
                {
                  type: 'custom',
                  render: (p) => <BoxArrowUpRight onClick={p.click} size={24} style={{alignSelf: 'center'}}></BoxArrowUpRight>,
                  onClick: () => {
                    navigate(`/admin/festival`, { state: { itemId: festival.id } })
                  },
                }
              ]
            } as Row;
          })
        ]
      })
    }
    
    return result;
  };

  const postSubmit = (): Promise<undefined | { [key: string]: string }> => {
    return Promise.resolve(undefined)
  }

  return { builder, postSubmit }
}