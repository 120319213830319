import React, { createRef, ReactNode, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { ChevronUp, ChevronDown, ChevronExpand } from 'react-bootstrap-icons';

type ValueType = 'string' | 'image' | 'roundImage' | 'date';

export interface DisplayItem<T> {
  name: string,
  accessor?: string | string[] | ((item: T) => any),
  type?: ValueType,
  valueGenerator?: (v: any) => JSX.Element,
  width?: number,
  sort?: 'ascending' | 'descending' | 'disable',
}

export interface Props<T> {
  loading?: boolean,
  items: T[],
  display: DisplayItem<T>[],
  select?: (item: T) => void,
  sort: (name: string, state: 'ascending' | 'descending' | 'disable' | undefined) => void,
}

function ValueWidth<T>(type: DisplayItem<T>){
  return type.width!== undefined?type.width:type.type === 'image'? 2: type.type === 'roundImage'? 1: 4;
}

function ValueWidthPercentage<T>(total: number, type: DisplayItem<T>){
  return `${Math.floor(ValueWidth(type)/total*100)}%`;
}

export function AccessValue<T>(item: T, type: DisplayItem<T>) {
  let itemValue = '';
  if (type.accessor === undefined) {
    itemValue = ''
  } else if (typeof type.accessor === 'string') {
    itemValue = (item as any)[type.accessor];
  } else if (typeof type.accessor === 'function') {
    itemValue = type.accessor(item);
  } else {
    let v = item as any;
    type.accessor.forEach(k => {
      v = v === undefined? undefined: v[k];
    })
    itemValue = v;
  }

  if (type.valueGenerator) {
    return type.valueGenerator(itemValue);
  } else if (type.type === 'roundImage') {
    return <img style={{borderRadius: '50%', width: '100%', aspectRatio: 1}} src={itemValue} alt={type.name}/>
  } else if (type.type === 'image') {
    return <img style={{width: '100%'}} src={itemValue} alt={type.name}/>
  } else if (type.type === 'date') {
    if (typeof itemValue !== 'number') {
      return <p style={{padding: 5}}>NA</p>;
    }
    const d = new Date(itemValue*1000);
    return <p style={{padding: 5}}>{`${d.getDate().toString().padStart(2,'0')}/${(d.getMonth()+1).toString().padStart(2,'0')}/${d.getFullYear().toString().padStart(4,'0')} ${(d.getHours()>12?d.getHours()-12:d.getHours()).toString().padStart(2,'0')}:${d.getMinutes().toString().padStart(2,'0')} ${d.getHours()>=12?'PM':'AM'}`}</p>
  }
  return <p style={{padding: 5}}>{itemValue}</p>;
};

export const List = function<T>(props: Props<T>) {
  
  function ItemRow(item: T, classes: string = '') {
    return <div className={`row item ${classes}`} style={{ minHeight: 38 }} onClick={() => {
      if (props.select) {
        props.select(item);
      }
    }}>
      {props.display.map(v => <div className='cell' style={{width: ValueWidthPercentage(totalWidth, v)}}>{AccessValue(item,v)}</div>)}
    </div>
  }

  const totalWidth = props.display.map(v => ValueWidth(v)).reduce((a,b) => a+b,0);
  return <div className='audioplay-list' style={props.loading? {height: '100%' }:undefined}>
    <div className='row header'>
      {props.display.map(v => <div className='cell' style={{width: ValueWidthPercentage(totalWidth, v)}} onClick={() => props.sort(v.name, v.sort)}>{v.name}{v.sort === 'disable'? undefined: v.sort === 'ascending'? <ChevronUp></ChevronUp>: v.sort === 'descending'? <ChevronDown></ChevronDown>: <ChevronExpand></ChevronExpand>}</div>)}
    </div>
    {props.loading? 
      <div style={{margin: 'auto'}}><Spinner animation="border" role="status"/></div>: 
      props.items.map((item, index) => ItemRow(item, index%2===1?'odd':''))}
  </div>
}