import React from 'react';
import { BaseButton, Props } from './BaseButton';

//export { Props };

export class Button extends BaseButton {
  constructor(props: Props) {
    super({ ...props, style: {
      ...props.style,
      fontFamily: 'Biryani',
      fontSize: 'large',
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 10,
      paddingBottom: 10,
    }, children: [<>{props.value}</>]});
  }

  getStyle() {
    return {
      ...this.props.style,
      fontFamily: 'Biryani',
      fontSize: 'large',
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 10,
      paddingBottom: 10,
    };
  }

  label(): string | JSX.Element {
    return this.props.value;
  }

}