import React, { Component } from 'react';
import { Navigate, redirect, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Elements } from '../../components';

import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

import { makeRequest } from '../../helper/request';
import { login } from '../../state/action/user';
import { state } from '../../state/reducer/user';


export interface Props extends HeaderProps {
  login: typeof login,
  user: state,
};

export const ReportsBase = (props: Props) => {
  const navigate = useNavigate();
  if (props.user.accessToken === undefined) {
    return <Navigate to="/" />;
  }
  return <><Elements.Button value='New' /><Block.Block alternate={true}>
    <Elements.Button value='New' />
    <Standard.StandardContent className='content-holder' style={{ flex: 1 }} >
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div>AUDIOPLAY Reports</div>
        <Elements.IconButton value='Plus' variant="audioplay-secondary" click={() => navigate('/admin/report' )}/>
      </div>
      
      <Elements.Spinner height={100} />
    </Standard.StandardContent>
  </Block.Block></>;
}

export const Reports = connect((state: any) => ({ user: state.user }), { login })(ReportsBase);