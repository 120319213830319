import React, { Component, ReactNode, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Model, models, processor } from '@audioplay/types';

import { Login } from '../../account/Login';
import { Signup } from '../../account/Signup';
import { Account } from '../../account/Account';
import { ResetPassword } from '../../account/ResetPassword';
import { NewPassword } from '../../account/NewPassword';
import { FinishAccountCreation } from '../../account/FinishAccountCreation';

import * as Festival from '../../festival';
import * as Register from '../../registration';
import * as Admin from '../../admin';
import * as Web from '../../web';
import * as DataModel from '../../../components/model'
import * as Training from '../../training';
import * as Test from '../../test';

import { Page404 } from '../../public/404';
import { Error } from '../../public/Error';
import * as AudioplayClient from '@audioplay/client';
import { FieldDefinition, ReferenceCollection } from '../../../components/content/ItemModal';
import { RouteBundleProps } from '../common';

type ModelProcessResult = { listFields: FieldDefinition<any,any>[], displayFields: FieldDefinition<any,any>[], directReferences: { model: Model.Model<{}, {}>, path: string[] }[], references: { model: Model.Model<{}, {}>, relation?: string | undefined, path: string[] }[] };

export interface DynamicData {
  autoRoutes: React.ReactElement[],
  objectRoutes: { name: string, path: string }[],
}

export const useDynamicRouting = (context: AudioplayClient.Context): DynamicData => {
  const [data] = useState(() => {
    const autoRoutes: React.ReactElement[] = [];
    const objectRoutes: { name: string, path: string }[] = [];

    const typeReferences: { [key: string]: ReferenceCollection<any,any> } = {};
    
    const p = processor.makeFullTypeProcessor<{}, {}, { displayType: Model.DisplayType, required: boolean, checked: Model.Structure<{},{}>[], model: Model.Model<{},{}> }, ModelProcessResult>({
      object: (obj, path, data, process) => {
        const r: ModelProcessResult = { listFields: [], displayFields: [], references: [], directReferences: [] }
        Object.entries(obj.properties).forEach(([k,v]) => {
          if (v.properties.exported === false || (v.properties.uiConfiguration && v.properties.uiConfiguration.display === Model.DisplayType.None)) {
            return;
          }
          const g = process(v.type, [...path, k], { displayType: v.properties.uiConfiguration && v.properties.uiConfiguration.display !== undefined? v.properties.uiConfiguration.display: data.displayType, required: v.properties.optional !== true, checked: data.checked, model: data.model });
          r.listFields = [...r.listFields, ...g.listFields];
          r.displayFields = [...r.displayFields, ...g.displayFields];
          r.references = [...r.references, ...g.references];
          r.directReferences = [...r.directReferences, ...g.directReferences];
        });
        if (obj.attributes.keyIndex) {
          r.references.push({
            relation: obj.attributes.keyIndex.relation,
            model: obj.attributes.keyIndex.model,
            path: path.map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
          })
        }
        return r;
      },
      struct: (obj, path, data, process) => {
        const r: ModelProcessResult = { listFields: [], displayFields: [], references: [], directReferences: [] }
        if (data.checked.includes(obj)) {
          return r;
        }
        data.checked.push(obj);
        Object.entries(obj.properties).forEach(([k,v]) => {
          if (v.properties.exported === false || (v.properties.uiConfiguration && v.properties.uiConfiguration.display === Model.DisplayType.None)) {
            return;
          }
          const g = process(v.type, [...path, k], { displayType: v.properties.uiConfiguration && v.properties.uiConfiguration.display !== undefined? v.properties.uiConfiguration.display: data.displayType, required: v.properties.optional !== true, checked: data.checked, model: data.model });
          r.listFields = [...r.listFields, ...g.listFields];
          r.displayFields = [...r.displayFields, ...g.displayFields];
          r.references = [...r.references, ...g.references];
          r.directReferences = [...r.directReferences, ...g.directReferences];
        });
        return r;
      },
      array: (obj, path, data, process) => {
        if (obj.value.properties.exported === false || (obj.value.properties.uiConfiguration && obj.value.properties.uiConfiguration.display === Model.DisplayType.None)) {
          return { listFields: [], displayFields: [], references: [], directReferences: [] };
        }
        let k = '~num';
        if (obj.attributes.keyIndex) {
          k = `~num[${obj.attributes.keyIndex.model.config.name}]`
        }
        const inner = process(obj.value.type, [...path, k], { displayType: obj.value.properties.uiConfiguration && obj.value.properties.uiConfiguration.display !== undefined? obj.value.properties.uiConfiguration.display: data.displayType, required: obj.value.properties.optional !== true, checked: data.checked, model: data.model });
        const r: ModelProcessResult = { listFields: inner.listFields, displayFields: [], references: inner.references, directReferences: inner.directReferences };
        if (obj.attributes.keyIndex) {
          inner.references.push({
            relation: obj.attributes.keyIndex.relation,
            model: obj.attributes.keyIndex.model,
            path: [...path, k].map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
          })
          inner.displayFields = [
            {
              key: [...path].map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
              required: data.required,
              type: 'id',
              modelSelect: obj.attributes.keyIndex.model,
            },
            ...inner.displayFields
          ]
        } else {
          inner.displayFields = [
            {
              key: [...path].map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
              required: data.required,
              type: 'string',
            },
            ...inner.displayFields
          ]
        }
        r.displayFields.push({
          key: path.map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
          required: true,
          type: 'collection',
          subTemplate: inner.displayFields,
        })
        return r;
      },
      map: (obj, path, data, process) => {
        if (obj.value.properties.exported === false || (obj.value.properties.uiConfiguration && obj.value.properties.uiConfiguration.display === Model.DisplayType.None)) {
          return { listFields: [], displayFields: [], references: [], directReferences: [] };
        }
        let k = '~str';
        if (obj.attributes.keyIndex) {
          k = `~str[${obj.attributes.keyIndex.model.config.name}]`
        }
        const inner = process(obj.value.type, [...path, k], { displayType: obj.value.properties.uiConfiguration && obj.value.properties.uiConfiguration.display !== undefined? obj.value.properties.uiConfiguration.display: data.displayType, required: obj.value.properties.optional !== true, checked: data.checked, model: data.model });
        const r: ModelProcessResult = { listFields: inner.listFields, displayFields: [], references: inner.references, directReferences: inner.directReferences };
        if (obj.value.type.attributes.keyIndex) {
          inner.references.push({
            relation: obj.value.type.attributes.keyIndex.relation,
            model: obj.value.type.attributes.keyIndex.model,
            path: [...path, k].map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
          })
          inner.displayFields = [
            {
              key: [...path].map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
              required: data.required,
              type: 'id',
              modelSelect: obj.value.type.attributes.keyIndex.model,
            },
            ...inner.displayFields
          ]
        } else {
          inner.displayFields = [
            {
              key: [...path].map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
              required: data.required,
              type: 'string',
            },
            ...inner.displayFields
          ]
        }
        r.displayFields.push({
          key: path.map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
          required: true,
          type: 'collection',
          subTemplate: inner.displayFields,
          labels: inner.displayFields.map(f => (typeof f.key === 'function'? []: (Array.isArray(f.key)? f.key: [f.key])).join('.'))
        })
        return r;
      },
      option: (obj, path, data, process) => {
        const r: ModelProcessResult = { listFields: [], displayFields: [], references: [], directReferences: [] }
        Object.entries(obj.options).forEach(([k,v]) => {
          if (v.properties.exported === false || (v.properties.uiConfiguration && v.properties.uiConfiguration.display === Model.DisplayType.None)) {
            return;
          }
          const g = process(v.type, path, { displayType: v.properties.uiConfiguration && v.properties.uiConfiguration.display !== undefined? v.properties.uiConfiguration.display: data.displayType, required: v.properties.optional !== true, checked: data.checked, model: data.model });
          r.listFields = [...r.listFields, ...g.listFields];
          r.displayFields = [...r.displayFields, ...g.displayFields];
          r.references = [...r.references, ...g.references];
          r.directReferences = [...r.directReferences, ...g.directReferences];
        });
        if (obj.attributes.keyIndex) {
          r.references.push({
            relation: obj.attributes.keyIndex.relation,
            model: obj.attributes.keyIndex.model,
            path: path.map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
          })
        }
        return r;
      },
      ref: (obj, path, data, process) => {
        const r: ModelProcessResult = { listFields: [], displayFields: [], references: [], directReferences: [] };
        if (data.displayType === Model.DisplayType.None) {
          return r;
        }
        r.directReferences.push({
          model: obj.refType,
          path: path.map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
        })

        r.displayFields.push({
          key: path.map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
          required: data.required,
          type: 'id',
          modelSelect: obj.refType,
        });

        return r;
      },
      token: (obj, path, data, process) => {
        return process(obj.value, path, data);
      },
      type: (obj, path, data, process) => {
        // return process(obj.getType(), path, data);
        return { listFields: [], displayFields: [], references: [], directReferences: [] };
      },
      literal: (obj, path, data, process) => {
        const r: ModelProcessResult = { listFields: [], displayFields: [], references: [], directReferences: [] };
        if (obj.attributes.index) {
          r.references.push({
            model: obj.attributes.index.model,
            relation: obj.attributes.index.relation,
            path: path.map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
          });
        }
        if (path[path.length-1] === 'id' && path.length === 1) {
          return r;
        }
        if (data.displayType === Model.DisplayType.None) {
          return r;
        }
        if (data.displayType === Model.DisplayType.List || data.displayType === Model.DisplayType.Detail) {
          r.displayFields.push({
            key: path.map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
            required: data.required,
            type: obj.value as any,
          });
        }
        if (data.displayType === Model.DisplayType.List) {
          r.listFields.push({
            key: path.map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
            required: data.required,
            type: obj.value as any,
          });
        }
        return r;
      },
      basic: (obj, path, data, process) => {
        const r: ModelProcessResult = { listFields: [], displayFields: [], references: [], directReferences: [] };
        if (obj.attributes.index) {
          r.references.push({
            model: obj.attributes.index.model,
            relation: obj.attributes.index.relation,
            path: path.map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
          });
        }
        if (data.displayType === Model.DisplayType.None) {
          if (path[path.length-1] === 'id' && path.length === 1) {
            r.listFields.push({
              key: path.map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
              required: true,
              type: 'string',
            });
          }
          return r;
        }
        if (data.displayType === Model.DisplayType.List || data.displayType === Model.DisplayType.Detail) {
          r.displayFields.push({
            key: path.map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
            required: data.required,
            type: obj.type,
            name: path.map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p).join('.')
          });
        }
        if (data.displayType === Model.DisplayType.List) {
          r.listFields.push({
            key: path.map(p => p instanceof processor.Block? p.asString({}, true): typeof p === 'number'? p.toString(): p),
            required: data.required,
            type: obj.type,
          });
        }
        return r;
      },
    });

    Object.entries(models).forEach(([n,m]) => {
      if (m instanceof Model.Model) {
        const data = p(m, [], {
          displayType: Model.DisplayType.Detail,
          required: true,
          checked: [],
          model: m,
        }, { directReferences: [], displayFields: [], listFields: [], references: []});
        data.references.forEach(r => {
          if (typeReferences[r.model.config.name] === undefined) {
            typeReferences[r.model.config.name] = {};
          }
          if (typeReferences[r.model.config.name][m.config.name] === undefined) {
            typeReferences[r.model.config.name][m.config.name] = {
              model: m,
              references: [],
            };
          }
          typeReferences[r.model.config.name][m.config.name].references.push({
            name: r.relation || `${m.config.name}s`,
            path: r.path,
          })
        });
        data.directReferences.forEach(r => {
          if (typeReferences[r.model.config.name] === undefined) {
            typeReferences[r.model.config.name] = {};
          }
          if (typeReferences[r.model.config.name][m.config.name] === undefined) {
            typeReferences[r.model.config.name][m.config.name] = {
              model: m,
              references: [],
            };
          }
          typeReferences[r.model.config.name][m.config.name].references.push({
            name: `${m.config.name}s`,
            path: r.path,
          })
        })
      }
    });
    
    Object.entries(models).forEach(([n,m]) => {
      if (m instanceof Model.Model) {
        const data = p(m, [], {
          displayType: Model.DisplayType.Detail,
          required: true,
          checked: [],
          model: m
        }, { directReferences: [], displayFields: [], listFields: [], references: []});
        if (data.listFields.length === 0) {
          data.listFields.push({
            key: 'id',
            name: 'ID',
            required: true,
            type: 'id',
          })
        }
        const model: React.FunctionComponent | undefined = {
          'User': DataModel.User,
          'Festival': DataModel.Festival,
          'Campaign': DataModel.Campaign,
          // 'SignupToken': DataModel.SignupToken,
          // 'Registration': DataModel.Registration,
        }[m.config.name] as any;

        objectRoutes.push({
          name: m.config.name,
          path: `/admin/${n.toLowerCase()}`,
        });
        const itemControl = <Admin.ListPage
          // key={m.config.name}
          modelName={m.config.name}
          newItem={() => new (AudioplayClient as any)[`${m.config.name}`]({})}
          itemModal={model !== undefined? (props: any) => React.createElement(model, props): undefined}
          references={typeReferences[m.config.name] || {}}
          binding={(AudioplayClient as any)[`${m.config.name}Binding`].getBinding(context)}
          context={context}
          listFields={data.listFields}
          detailFields={data.displayFields}
        />;
      
        autoRoutes.push(<Route
          key={m.config.name}
          path={`/admin/${n.toLowerCase()}`}
          element={itemControl}
        />);
        autoRoutes.push(<Route
          key={m.config.name}
          path={`/admin/${n.toLowerCase()}/:itemId`}
          element={itemControl}
        />);
      }
    });

    return { autoRoutes, objectRoutes };
  });
  
  return data;
}

export const autoAdmin = (data: DynamicData) => {
  return data.autoRoutes;
}
