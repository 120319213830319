import { useState } from 'react'; 
import { FestivalBinding, CampaignBinding, Context, Festival as FestivalModel } from '@audioplay/client';
import { Validate } from '@audioplay/client/lib/model/Festival';
import { useNavigate } from 'react-router-dom';

import { FormBinding, useBinding } from './FormBinding';
import { Base as ModelBase, useItemCollection, useItemCollections } from './Base';
import { episodeInformation } from './Data';
import { Form, Control, Section, Row } from '../../components/form';

export interface Props {
  item: FestivalModel,
  newItem: boolean,
  context: Context,
  closed: () => void
}

export function Festival(props: Props) {
  const { loading, items } = useItemCollections({
  });
  const binding = useBinding(props.item);
  const navigate = useNavigate();

  const template: (Control | Section | Row)[] = [];

  if (!loading) {
    template.push({
      type: 'section',
      title: 'Details',
      items: [
        binding.bind({
          type: 'text',
          title: 'Name'
        }, ['name']),
        binding.bind({
          type: 'datetime',
          title: 'Start',
          optional: true,
        }, ['start']),
        binding.bind({
          type: 'datetime',
          title: 'End',
          optional: true,
        }, ['end']),
      ]
    });

    const images = [
      {
        name: 'Icon',
        id: 'Icon',
        width: 100,
        height: 100,
        mask: 'circle'
      },
      {
        name: 'HeaderCard',
        id: 'HeaderCard',
        width: 100,
        height: 100,
      },
      {
        name: 'TitleCard',
        id: 'TitleCard',
        width: 200,
        height: 100,
      }
    ];
    const imgs: Control[] = images.map(i => {
      
      return binding.bindCollection({
        type: 'img',
        value: '#[value]',
        title: i.name,
        display: {
          props: {
            imageWidth: i.width,
            imageHeight: i.height,
            mask: i.mask as 'circle',
          }
        }
      }, 'image.'+i.name, () => {
        const existing = (props.item.appConfiguration?.images || {})[i.name];
        return {
          value: existing===undefined || existing.file === undefined? undefined: existing.file.startsWith('http')? existing.file: process.env.DEFAULT_ASSET_URL+'/'+existing.file,
        }
      }, (values) => {
        if (values.value === undefined) {
          delete (props.item.appConfiguration?.images || {})[i.name];
        } else {
          if (props.item.appConfiguration === undefined) {
            props.item.appConfiguration = {};
          }
          if (props.item.appConfiguration.images === undefined) {
            props.item.appConfiguration.images = {};
          }
          let v = values.value.toString();
          if (v.startsWith(process.env.DEFAULT_ASSET_URL || 'NONE')) {
            v = v.substring((process.env.DEFAULT_ASSET_URL || 'NONE').length+1);
          }
          props.item.appConfiguration.images[i.name] = {
            name: i.name,
            file: v,
            signature: '',
          };
        }
        return undefined;
      });
    });

    template.push({
      type: 'section',
      title: 'Images',
      items: [{
        type: 'row',
        items: imgs,
      }]
    });

    template.push({
      type: 'section',
      title: 'App Configuration',
      items: [{
        type: 'row',
        items:[
          binding.bind({
            type: 'text',
            title: 'Introduction',
            optional: true,
          }, ['appConfiguration', 'data','intro'], { generateIntermediateNodes: true, generateOnEmpty: false }),
          binding.bind({
            type: 'dropdown',
            title: 'Series Logo',
            optional: true,
            options: {
              '': { default: true, value: 'None' },
              'Main/Content/Title/TheTurners.png': 'The Turners',
              'Main/Content/Title/SunRunners.png': 'Sun Runners',
              'Main/Content/Title/PoochOnTheLoose.png': 'Pooch on the Loose',
            }
          }, ['appConfiguration', 'data','SeriesLogoFile'], { generateIntermediateNodes: true, generateOnEmpty: false }),
          binding.bind({
            type: 'text',
            title: 'Series',
            optional: true,
          }, ['appConfiguration', 'data','series'], { generateIntermediateNodes: true, generateOnEmpty: false }),
          binding.bind({
            type: 'dropdown',
            title: 'Series Font',
            options: {
              "Common/Fonts/Aerospace.ttf": "Aerospace",
              "Common/Fonts/Alien Android Italic.ttf": "Alien Android Italic",
              "Common/Fonts/Alien Android.ttf": "Alien Android",
              "Common/Fonts/AlienAbduction.ttf": "AlienAbduction",
              "Common/Fonts/ArchitectsDaughter.ttf": "ArchitectsDaughter",
              "Common/Fonts/Armageda Wide.ttf": "Armageda Wide",
              "Common/Fonts/Bangers-Regular-r.ttf": "Bangers-Regular-r",
              "Common/Fonts/Bangers-Regular.ttf": "Bangers-Regular",
              "Common/Fonts/Biryani-Bold.ttf": "Biryani-Bold",
              "Common/Fonts/Biryani-Regular.ttf": { default: true, value: "Biryani-Regular" },
              "Common/Fonts/ComingSoon.ttf": "ComingSoon",
              "Common/Fonts/Knewave.ttf": "Knewave",
              "Common/Fonts/Open 24 Display.ttf": "Open 24 Display",
              "Common/Fonts/Poppins-Bold.ttf": "Poppins-Bold",
              "Common/Fonts/Poppins-Regular.ttf": "Poppins-Regular",
              "Common/Fonts/RumRaisin-Regular.ttf": "RumRaisin-Regular",
              "Common/Fonts/Squartiqa.ttf": "Squartiqa",
            }
          }, ['appConfiguration', 'data','seriesFont'], { generateIntermediateNodes: true, generateOnEmpty: false }),
          binding.bind({
            type: 'text',
            title: 'Date',
            optional: true,
          }, ['appConfiguration', 'data','date'], { generateIntermediateNodes: true, generateOnEmpty: false }),
          binding.bind({
            type: 'text',
            title: 'Presenter',
            optional: true,
          }, ['appConfiguration', 'data','PresenterName'], { generateIntermediateNodes: true, generateOnEmpty: false }),
        ]
      },
      {
        type: 'row',
        items: [
          binding.bind({
            type: 'textarea',
            title: 'About',
            optional: true,
          }, ['appConfiguration', 'data','about'], { generateIntermediateNodes: true, generateOnEmpty: false }),
        ]
      }]
    });
    
    const access: Row[] = episodeInformation.map(i => {
      return binding.bindCollection({
        type: 'row',
        items: [
          {
            type: 'img',
            value: i.img,
            display: {
              disabled: true,
              props: {
                imageWidth: '100px',
                imageHeight: '100px'
              }
            }
          },
          {
            type: 'label',
            value: i.title,
            display: {
              style: {
                width: '250px',
              }
            }
          },
          {
            type: 'checkbox',
            value: '#[added]',
            optional: true,
            display: {
              style: {
                width: '65px',
              }
            }
          },
          {
            type: 'datetime',
            value: '#[start]',
            optional: true,
            display: {
              group: {
                style: {
                  width: '200px',
                }
              }
            }
          },
          {
            type: 'datetime',
            value: '#[end]',
            optional: true,
            display: {
              group: {
                style: {
                  width: '200px',
                }
              }
            }
          },
          {
            type: 'timespan',
            value: '#[startOffset]',
            optional: true,
            display: {
              group: {
                style: {
                  width: '200px',
                }
              }
            }
          },
          {
            type: 'timespan',
            value: '#[endOffset]',
            optional: true,
            display: {
              group: {
                style: {
                  width: '200px',
                }
              }
            }
          }
        ]
      }, 'episode.'+i.productName, () => {
        const a = (props.item.episodeIds || []).find(e => e.product === i.productName);
        return {
          added: a !== undefined,
          start: (a !== undefined? a.start: undefined),
          end: (a !== undefined? a.end: undefined),
          startOffset: (a !== undefined && a.start === undefined && a.end === undefined? a.startOffset: undefined),
          endOffset: (a !== undefined && a.start === undefined && a.end === undefined? a.endOffset: undefined),
        };
      }, (values) => {
        if (values.added) {
          if (props.item.episodeIds === undefined) {
            props.item.episodeIds = [];
          }
          const index = (props.item.episodeIds || []).findIndex(e => e.product === i.productName);
          const s = typeof values.start === 'number'? values.start: undefined;
          const e = typeof values.end === 'number'? values.end: undefined;
          if (index !== undefined && index !== -1) {
            props.item.episodeIds[index] = {
              product: i.productName,
              productIds: i.productIds,
              start: s,
              end: e,
              startOffset: s === undefined && e === undefined && typeof values.startOffset === 'number'? values.startOffset: undefined,
              endOffset: s === undefined && e === undefined && typeof values.endOffset === 'number'? values.endOffset: undefined,
            };
          } else {
            props.item.episodeIds.push({
              product: i.productName,
              productIds: i.productIds,
              start: s,
              end: e,
              startOffset: s === undefined && e === undefined && typeof values.startOffset === 'number'? values.startOffset: undefined,
              endOffset: s === undefined && e === undefined && typeof values.endOffset === 'number'? values.endOffset: undefined,
            });
          }
        } else {
          const index = (props.item.episodeIds || []).findIndex(e => e.product === i.productName);
          if (index !== undefined && index !== -1) {
            (props.item.episodeIds || []).splice(index,1);
          }
        }
        return undefined;
      });
    })
    template.push({
      type: 'section',
      items: [{
        type: 'row',
        id: 'episode.title.row',
        display: {
          style: {
            justifyContent: 'center',
            alignItems: 'center',
          }
        },
        items: [
          {
            type: 'label',
            value: 'Access',
            display: {
              classNames: 'title text'
            }
          },
        ],
      },
      {
        type: 'row',
        items:[
          {
            type: 'label',
            value: '',
            display: {
              style: {
                width: '100px',
              }
            }
          },
          {
            type: 'label',
            value: 'Episode',
            display: {
              style: {
                width: '250px',
              }
            }
          },
          {
            type: 'label',
            value: 'Access',
            display: {
              style: {
                width: '65px',
              }
            }
          },
          {
            type: 'label',
            value: 'Access Start',
            display: {
              style: {
                width: '200px',
              }
            }
          },
          {
            type: 'label',
            value: 'Access End',
            display: {
              style: {
                width: '200px',
              }
            }
          },
          {
            type: 'label',
            value: 'Access Start Offset',
            display: {
              style: {
                width: '200px',
              }
            }
          },
          {
            type: 'label',
            value: 'Access End Offset',
            display: {
              style: {
                width: '200px',
              }
            }
          },
        ]
      },
      ...access,
    ]
    });
  }
  template.push({
    type:'label',
    value: props.newItem? 'NEW FESTIVAL': (props.item.name || ''),
    display: {
      section: 'header',
      style: {
        fontSize: 'x-large',
        fontWeight: 600
      }
    }
  });
  template.push({
    type: 'row',
    display: {
      section: 'footer',
    },
    items: [
      {
        type: 'button',
        value: 'CLOSE',
        onClick: 'close',
        validationRequired: false,
        display: {
          props: {
            variant: "secondary"
          }
        }
      },
      {
        type: 'button',
        value: 'SAVE CHANGES',
        onClick: 'save',
      }
    ]
  });
  return <ModelBase
    close={() => { props.closed() }}
    save={() => Promise.resolve()}
    loading={loading}
    content={(header, footer) =>
      <Form
        headerElement={header}
        footerElement={footer}
        submitHandler={(action, data, controller) => {
          if (action === 'close') {
            props.closed();
          } else if (action === 'save') {
            const e = binding.resolve(data);
            if (e) {
              return Promise.resolve(e);
            }
            props.item.version = props.item.version === undefined? 1: props.item.version+1;
            props.item.local = props.item.local === undefined? false: props.item.local;
            props.item.localBroadcast = props.item.localBroadcast === undefined? false: props.item.localBroadcast;
            
            return props.item.save(props.context).then((r) => {
              if (r) {
                props.closed();
                return undefined;
              } else {
                return { 
                  'saveButton': 'Could not save successfully',
                }
              }
            }).catch(() => {
              return { 
                'saveButton': 'Could not save successfully',
              }
            })
          }
          return Promise.resolve(undefined);
        }} 
        template={[
          {
            type: 'section',
            items: template,
          }
        ]}
        templateLockId={props.item.id}
      />
    }
  />
}