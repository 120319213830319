import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { Form, Control, Row, Section, ControlOptions } from '../form';
import { DataObject, Context } from '@audioplay/client';
import * as Client from '@audioplay/client';

import { models, processor, Model } from '@audioplay/types';
import { useLoader } from '../util/loader';

export interface FieldDefinition<M extends { id: string }, T extends DataObject<M>> {
  type: Model.BaseTypes | 'collection',
  key: string | string[] | ((item: T, value?: any | null) => any),
  name?: string,
  required: boolean,
  modelSelect?: Model.Model<any, any>,
  subTemplate?: FieldDefinition<any,any>[],
  labels?: string[],
}

export type ReferenceCollection<M extends { id: string }, T extends DataObject<M>> = {
  [key: string]: {
    model: Model.Model<any,any>,
    references: {
      name: string,
      path: string | string[] | ((item: DataObject<any>, value?: any | null) => any),
    }[],
  }
};

export const processPath = (value: any, path: string[]): string[] => {
  if (value === undefined) {
    return [];
  }
  if (path.length === 0) {
    return typeof value === 'string'? [value]: value;
  }
  
  let v = value;
  if (path[0] === '~str' || path[0] === '~num' || path[0] === '~key') {
    if (path.length === 1) {
      return Object.keys(v);
    }
    let r: string[] = [];
    Object.values(v).forEach(k => {
      r = [...r, ...processPath(k, path.slice(1))];
    })
  }
  v = v[path[0]];
  return processPath(v, path.slice(1));
}

export const makeDisplayName = (name: string): string => {
  return name.split(/(?=[A-Z])/).map(a => a.substring(0,1).toUpperCase()+a.substring(1)).join(' ');
}

const getFormTemplate = (item: any): { form: (Control | Section | Row)[], additions: Promise<void>[] } => {
  const getImage = (name: string) => {
    const data = item['appConfiguration']['images'].find((i: any) => i.name === name);
    if (data && data.file) {
      return data.file.startsWith('http')? data.file:`${process.env.DEFAULT_ASSET_URL}/${data.file}`;
    } else {
      return undefined;
    }
  }
  const getData = (name: string) => {
    const data = item['appConfiguration']['data'][name];
    return data;
  }

  const items: (Control | Section | Row)[] = [
    {
      id: 'name',
      type: 'text',
      title: 'Name',
      value: item['name'],
    },
    {
      type: 'row',
      items: [
        {
          id: 'start',
          type: 'datetime',
          title: 'Start',
          value: item['start'],
        },
        {
          id: 'end',
          type: 'datetime',
          title: 'End',
          value: item['end'],
        },
      ]
    },
    {
      type: 'section',
      title: 'App',
      items: [
        {
          type: 'row',
          items: [
            {
              id: 'app.title',
              type: 'text',
              title: 'Title',
              value: getData('series'),
            },
            {
              id: 'app.titleFont',
              type: 'dropdown',
              title: 'Title Font',
              value: getData('seriesFont'),
              options: {
                '': { default: true, value: 'None' },
                "Common/Fonts/Aerospace.ttf": "Aerospace",
                "Common/Fonts/Alien Android Italic.ttf": "Alien Android Italic",
                "Common/Fonts/Alien Android.ttf": "Alien Android",
                "Common/Fonts/AlienAbduction.ttf": "AlienAbduction",
                "Common/Fonts/ArchitectsDaughter.ttf": "ArchitectsDaughter",
                "Common/Fonts/Armageda Wide.ttf": "Armageda Wide",
                "Common/Fonts/Bangers-Regular-r.ttf": "Bangers-Regular-r",
                "Common/Fonts/Bangers-Regular.ttf": "Bangers-Regular",
                "Common/Fonts/Biryani-Bold.ttf": "Biryani-Bold",
                "Common/Fonts/Biryani-Regular.ttf": "Biryani-Regular",
                "Common/Fonts/ComingSoon.ttf": "ComingSoon",
                "Common/Fonts/Knewave.ttf": "Knewave",
                "Common/Fonts/Open 24 Display.ttf": "Open 24 Display",
                "Common/Fonts/Poppins-Bold.ttf": "Poppins-Bold",
                "Common/Fonts/Poppins-Regular.ttf": "Poppins-Regular",
                "Common/Fonts/RumRaisin-Regular.ttf": "RumRaisin-Regular",
                "Common/Fonts/Squartiqa.ttf": "Squartiqa",
              }
            },
            {
              id: 'app.titleLogo',
              type: 'dropdown',
              title: 'Title Logo',
              value: getData('SeriesLogoFile'),
              options: {
                '': { default: true, value: 'None' },
                'Main/Content/Title/TheTurners.png': 'The Turners',
                'Main/Content/Title/SunRunners.png': 'SunRunners'
              }
            }
          ]
        },
        {
          id: 'app.introduction',
          type: 'text',
          title: 'Introduction',
          value: getData('intro'),
        },
        {
          id: 'app.about',
          type: 'textarea',
          title: 'About',
          value: getData('about'),
        },
      ]
    },
    {
      type: 'section',
      title: 'Images',
      items: [
        {
          type: 'row',
          items: [
            {
              id: 'app.icon',
              type: 'img',
              title: 'Icon',
              value: getImage('Icon'),
              display: {
                props: {
                  mask: 'circle',
                  imageHeight: 100,
                  imageWidth: 100,
                }
              }
            },
            {
              id: 'app.logo',
              type: 'img',
              title: 'Logo',
              value: getImage('Logo'),display: {
                props: {
                  imageHeight: 100,
                  imageWidth: 100,
                }
              }
            },
            {
              id: 'app.header',
              type: 'img',
              title: 'HeaderCard',
              value: getImage('HeaderCard'),
              display: {
                props: {
                  imageHeight: 100,
                  imageWidth: 100,
                }
              }
            },
            {
              id: 'app.card',
              type: 'img',
              title: 'TitleCard',
              value: getImage('TitleCard'),
              display: {
                props: {
                  imageHeight: 100,
                  imageWidth: 200,
                }
              }
            }
          ]
        }
      ]
    },
    {
      display: {
        section: 'header',
        style: {
          fontSize: 'x-large',
          fontWeight: 600
        },
      },
      type:'label',
      value: item['name'],
    },
    {
      display: {
        section: 'header',
      },
      type: 'row',
      items: [
        {
          type: 'button',
          value: 'CLOSE',
          onClick: 'close',
          display: {
            props: {
              variant: "secondary"
            }
          }
        },
        {
          type: 'button',
          value: 'SAVE CHANGES',
          onClick: 'save',
        }
      ]
    }
  ];

  return { form: items, additions: [] };
}

const makeGeneric = () => {
  processor.makeFullTypeProcessor<{}, {}, {}, {valid: boolean}>({
    object: (obj, path, data, process) => {
      return { valid: false };
    },
    struct: (obj, path, data, process) => {
      return { valid: false };
    },
    array: (obj, path, data, process) => {
      return { valid: false };
    },
    map: (obj, path, data, process) => {
      return { valid: false };
    },
    option: (obj, path, data, process) => {
      return { valid: false };
    },
    ref: (obj, path, data, process) => {
      return { valid: false };
    },
    token: (obj, path, data, process) => {
      return { valid: false };
    },
    type: (obj, path, data, process) => {
      return { valid: false };
    },
    literal: (obj, path, data, process) => {
      return { valid: false };
    },
    basic: (obj, path, data, process) => {
      return { valid: false };
    },
  })(models.Festival, [], {}, { valid: false });
}

function getFields<M extends { id: string }, T extends DataObject<M>>(item: {}, itemLists: { [key: string]: DataObject<any>[] }, fields: FieldDefinition<M,T>[], references: ReferenceCollection<M,T>, options: { pathPrefix?: string[], baseProperty?: any, collection?: boolean } = {}) {
  const requiredBindings: { [key: string]: Model.Model<any,any>} = {};
  const baseItems: ((Control & { baseType: Model.BaseTypes }) | Row | Section)[] = [];
  fields.forEach(f => {
    let v = item as any;
    let id = '';
    let name = f.name !== undefined? f.name: typeof f.key === 'function'? undefined: makeDisplayName(Array.isArray(f.key)?f.key[f.key.length-1]:f.key)
    name = typeof f.key === 'function'? name: (Array.isArray(f.key)?f.key:[f.key]).join('.');
    if (typeof f.key === 'function') {
      v = f.key(v);
    } else {
      let useBase = false;
      let l = (Array.isArray(f.key)?f.key:[f.key]);
      if (options.pathPrefix !== undefined) {
        useBase = l.length < options.pathPrefix.length;
        l = l.slice(options.pathPrefix.length);
      }
      l.forEach((k) => {
        v = v === undefined? undefined: v[k];
      });
      if (useBase && options.baseProperty !== undefined) {
        v = options.baseProperty;
      }
      id = l.join('.');
    }
    if (f.type === 'collection') {
      if (options.collection === true) {  
        return;
      }
      const subRows: Section[] = [];
      const pathPrefix = [...(typeof f.key === 'function'? []: (Array.isArray(f.key)?f.key:[f.key])), '~str'];
      if (v !== undefined) {
        Object.entries(v).forEach(([k,v]: [string, any]) => {
          const row: Section = {
            type: 'section',
            items: [],
          };
          const r = getFields(v, itemLists, f.subTemplate || [], references, { ...options, pathPrefix, baseProperty: k, collection: true });
          let items: (Control)[] = [];
          r.baseItems.forEach((i) => {
            if (i.type === 'row' || i.type === 'section') {
              if (items.length > 0) {
                row.items.push({
                  type: 'row',
                  items
                })
              } 
              row.items.push(i)
              items = []
            } else {
              items.push(i)
            }
          })
          if (items.length > 0) {
            row.items.push({
              type: 'row',
              items
            })
          }
          Object.entries(r.requiredBindings).forEach(([k,v]) => {
            requiredBindings[k] = v;
          });
          subRows.push(row);
        })
      }
      baseItems.push({
        type: 'section',
        title: 'Section: ' + (name || ''),
        items: [{
          type: 'row',
          items: [
            {
              type: 'label',
              value: 'SECTION'
            },
            {
              type: 'label',
              value: id,
            }
          ]
        },
        {
          type: 'row',
          items: (f.labels || []).map(o => ({ type: 'label', value: o })),

        }],
        // }, ...subRows],
      })
    
    } else if (f.type.includes('|')) {
      const options: ControlOptions = {};
      f.type.split('|').forEach(o => {
        let k = o.trim();
        if (k.length > 0 && k[0] === "'") {
          k = k.substring(1);
        }
        if (k.length > 0 && k[k.length-1] === "'") {
          k = k.substring(0,k.length-1);
        }
        options[k] = makeDisplayName(k);
      });

      baseItems.push({
        id,
        type: 'dropdown',
        options,
        title: name,
        value: v,
        baseType: f.type,
      })
    } else if (f.modelSelect !== undefined) {
      requiredBindings[f.modelSelect.config.name] = f.modelSelect;
      if (typeof f.key !== 'function') {
        let n = Array.isArray(f.key)?f.key[f.key.length-1]:f.key;
        n = n.endsWith('Id')? n.substring(0,n.length-2): n;
        name = `${makeDisplayName(n.trim())}[${id}]`;
      }

      let options: ControlOptions = {};
      const list = itemLists[f.modelSelect.config.name];
      if (list) {
        list.forEach((m) => {
          options[m['id']] = (m as any)['name'];
        });
      }
      options[''] = { value: 'None', default: true };
      baseItems.push({
        id,
        type: 'dropdown',
        title: name,
        value: v,
        options,
        baseType: f.type,
      })
    } else {
      baseItems.push({
        id,
        type: f.type === 'date'? 'datetime': f.type === 'bool'? 'checkbox': 'text',
        title: name,
        value: f.type === 'date'? (v===undefined?Date.now()*0.001:v) :v,
        baseType: f.type,
      })
    }
  });

  Object.entries(references).forEach(([n,v]) => {
    requiredBindings[v.model.config.name] = v.model;
  });

  return { baseItems, requiredBindings };
}

export interface Props<M extends { id: string }, T extends DataObject<M>> {
  item: T,
  closed: () => void,
  context: Context,
  fields: FieldDefinition<M,T>[],
  references: ReferenceCollection<M,T>,
}

export function ItemModal<M extends { id: string }, T extends DataObject<M>>(props: Props<M,T>) {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);
  const [header,setHeader] = useState<HTMLDivElement | undefined>(undefined);
  const [footer,setFooter] = useState<HTMLDivElement | undefined>(undefined);
  const [modelLists,setModelLists] = useState<{ [key: string]: DataObject<any>[] }>({});
  const [refLists,setRefLists] = useState<{ [key: string]: DataObject<any>[] }>({});
  const [loading,setLoading] = useState(true);
  const [itemId,setItemId] = useState(props.item.id);
  const navigate = useNavigate();

  const i = props.item as any;
  const { baseItems, requiredBindings } = getFields(props.item, modelLists, props.fields, props.references);

  useEffect(() => {
    if (headerRef.current) {
      setHeader(headerRef.current); 
    }
    if (footerRef.current) {
      setFooter(footerRef.current);
    }
    setItemId(props.item.id)
    setLoading(true);
    Promise.all(Object.keys(requiredBindings).map(n => {
      return new Promise<[string, DataObject<any>[]]>((res) => {
        (Client as any)[`${n}Binding`].getBinding(props.context)
          .bind((items: DataObject<any>[]) => res([n,items]));
      });
    }))
      .then((items) => {
        items.forEach(([n,i]) => {
          modelLists[n] = i;
        })
        setModelLists(modelLists);
        setLoading(false);
      })
  }, [props.item]);

  const items: (Control | Section | Row)[] = [];
  let row: (Control)[] = [];
  let length = 0;
  baseItems.forEach((i) => {
    if (i.type === 'section' || i.type === 'row') {
      if (row.length === 1) {
        items.push(row[0]);
      } else {
        items.push({
          type: 'row',
          items: row,
        })
      }
      items.push(i);
      row = [];
      length = 0;
    } else {
      const w = i.type === 'datetime'? 6: i.type === 'dropdown'? 4: i.type === 'checkbox'? 3: i.baseType === 'number'? 4: 12;
      if (length + w > 0) {
        if (row.length === 1) {
          items.push(row[0]);
        } else {
          items.push({
            type: 'row',
            items: row,
          })
        }
        row = [];
        length = 0;
      }
      length += w;
      row.push(i);
    }
  })
  if (row.length > 1) {
    items.push({
      type: 'row',
      items: row,
    })
  } else if (row.length === 1) {
    items.push(row[0]);
  }

  items.push({
    type:'label',
    value: i['name'],
    display: {
      section: 'header',
      style: {
        fontSize: 'x-large',
        fontWeight: 600
      }
    }
  });
  items.push({
    type: 'row',
    display: {
      section: 'footer',
    },
    items: [
      {
        type: 'button',
        value: 'CLOSE',
        onClick: 'close',
        display: {
          props: {
            variant: "secondary"
          }
        }
      },
      {
        type: 'button',
        value: 'SAVE CHANGES',
        onClick: 'save',
      }
    ]
  });
  Object.entries(props.references).forEach(([n,v]) => {
    const modelSet = modelLists[v.model.config.name] || [];
    v.references.forEach(r => {
      const filtered = modelSet.filter(m => {
        let ids = [];
        if (typeof r.path === 'function') {
          ids = r.path(m as any);
          ids = typeof ids === 'string'? [ids]: ids;
        } else {
          let l = (Array.isArray(r.path)?r.path:[r.path]);
          ids = processPath(m,l);
        }
        return ids.includes(props.item.id);
      })
      items.push({
        type: 'section',
        title: r.name,
        
        items: filtered.map(m => {
          return {
            type: 'row',
            onClick: () => {
              navigate(`/admin/${v.model.config.name.toLowerCase()}`, { state: { itemId: m.id} })
            },
            items: [
              {
                type: 'label',
                align: 'start',
                value: (m as any)['name'] || m['id'],
              }
            ]
          };
        })
      });
    });
  });
    
  return<Modal show={true} onHide={() => props.closed()} dialogClassName='audioplay-edit-model'>
    <Modal.Header>
      <div ref={headerRef}/>
    </Modal.Header>
    <Modal.Body>
      {loading?<Spinner animation='border'></Spinner>:
      <Form headerElement={header} footerElement={footer} templateLockId={itemId} submitHandler={(action, data, controller) => {
        if (action === 'close') {
          props.closed();
        } else if (action === 'save') {
          const errors: { [key: string]: string } = {};
          props.fields.forEach(f => {
            let id = typeof f.key === 'function'? '': (Array.isArray(f.key)?f.key:[f.key]).join('.');
            if (f.required && (data[id].valid === false || data[id].value === undefined || data[id].value === '')) {
              errors[id] = '* required';
              return;
            }
            let value: any = data[id].value;
            if (f.type === 'date') {
              value = value === undefined? undefined: value*0.001;
            }
            if (f.modelSelect && value === '') {
              value = undefined;
            } 
            if (typeof f.key === 'function') {
              f.key(i, data[id].valid && value !== undefined? value: null);
            } else {
              let v = i;
              let p = v;
              let l = (Array.isArray(f.key)?f.key:[f.key]);
              l.forEach((k) => {
                p = v;
                v = v === undefined? undefined: v[k];
              });
              if (p !== undefined) {
                p[l[l.length-1]] = value;
              };
            }
          });        
          return props.item.save(props.context)
            .then(() => {
              props.closed();
              return undefined;
            });
        }
        return Promise.resolve(undefined);
      }} template={[
        {
          type: 'section',
          items,
        }
      ]}/>}
      {/* <div>
        <h1>USERS</h1>
        <List items={users} loading={loading} select={(user) => {

        }} display={[
          {
            name: 'Name',
            accessor: 'name'
          },
          {
            name: 'Email',
            accessor: 'email'
          }
        ]}/>
      </div> */}
    </Modal.Body>
    <Modal.Footer>
      <>
      <div ref={footerRef}/>

      </>
    </Modal.Footer>
  </Modal>
}