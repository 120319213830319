import React, { Component, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeRequest, API } from '@audioplay/client';

import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

import { login } from '../../state/action/user';
import { state } from '../../state/reducer/user';

import ReactQuill, {} from 'react-quill';
import Quill, { DeltaStatic } from 'quill';

import 'react-quill/dist/quill.snow.css';
const Delta = Quill.import('delta');


export interface Props extends HeaderProps {

};

export const ContentEditor = (props: Props) => {
  // console.log(Delta)
  const [value, setValue] = useState<DeltaStatic>(new Delta());

  return <HeaderPage>
    <Block.Block alternate={true}>
      <Standard.StandardContent>
        
        <div className='editor-holder'>
          <ReactQuill theme="snow" value={value} onChange={(value, delta, source, editor) => {
            setValue(editor.getContents());
          }} />
        </div>
      </Standard.StandardContent>
    </Block.Block>
  </HeaderPage>;
}