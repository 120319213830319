import React from 'react';
import { BaseButton, Props } from './BaseButton';
import * as Icons from 'react-bootstrap-icons';
//export { Props };

export class IconButton extends BaseButton {

  getStyle() {
    return {
      ...this.props.style,
      fontFamily: 'Biryani',
      fontSize: 'large',
      padding: 2,
    }
  }
  label() {
    const Icon = (Icons as any)[this.props.value];
    return <Icon size={30}></Icon>;
  }
}