import React, { Component, useState } from 'react';
import { Navigate, redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

import { makeRequest } from '../../helper/request';
import { login } from '../../state/action/user';
import { state } from '../../state/reducer/user';


export interface Props extends HeaderProps {
  login: typeof login,
  user: state,
};

export const UserReportBase = (props: Props) => {
  const [deviceId, setDeviceId] = useState('');
  const [notice, setNotice] = useState(false);
  
  if (props.user.accessToken === undefined) {
    return <Navigate to="/" />;
  }
  return <Block.Block alternate={true}>
    <Standard.StandardContent className='content-holder' style={{ flex: 1 }} >
    <Form submitHandler={(action, data) => {
        if (action === 'get.info') {
          const id = `${data['userId'].value}`;
          setDeviceId(id);
          return makeRequest({
            type: 'admin.report.user',
            token: props.user.accessToken,
            userId: id,
          })
            .then((res) => {
              if (res.result !== 'success') {
                
                return {
                  ...res.errors,
                  'get.info': res.error,
                }
              } else {
                setNotice(true);
              }
              return undefined;
            });
        }
        return Promise.resolve({})
      }} template={[
        {
          type: 'section',
          items: [
            {
              type: 'row',
              items: [
                {
                  type: 'text',
                  title: 'User Name',
                  id: 'userId',
                  value: '',
                }
              ]

            }
          ]
        },
        {
          type: 'section',
          items: [
            {
              type: 'button',
              id: 'get.info',
              value: 'Run Report',
              onClick: 'get.info',
            },
          ]
        },

      ]}
      />
      <Modal key={'popup'} show={notice} onHide={() => {setNotice(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Report Submitted</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>The report is now running and will be emailed to you in a few minutes once ready.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="audioplay-secondary" onClick={() => {setNotice(false)}}>OK</Button>
        </Modal.Footer>
      </Modal>
    </Standard.StandardContent>
  </Block.Block>;
}

export const UserReport = connect((state: any) => ({ user: state.user }), { login })(UserReportBase);