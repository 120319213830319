import { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import * as AudioplayClient from '@audioplay/client';

import { Redirect } from '../../components/nav/Redirect';
import { Page404 } from '../public/404';
import { Error } from '../public/Error';
import { Account } from '../account/Account';

import * as Web from '../web';
import * as Admin from '../admin';

import { AppRouterProps, RouteBundleProps } from './common';
import { training, account, registration, web } from './public';
import { admin, dynamic } from './admin';


export const AppRouter = (props: AppRouterProps) => {
  const [wasLoggedIn, setWasLoggedIn] = useState(props.loggedIn);
  const [cacheContext] = useState(new AudioplayClient.Context(new AudioplayClient.DataCache()));
  const dynamicData = dynamic.useDynamicRouting(cacheContext);

  if (props.loggedIn && !wasLoggedIn) {
    setWasLoggedIn(true);
  }
  const bundleProps: RouteBundleProps = {
    loggedIn: props.loggedIn === true,
    context: cacheContext,
  };

  return <BrowserRouter>
      <Routes>
        {props.loggedIn?
        [
          <Route path="/" element={<Account objectRoutes={dynamicData.objectRoutes}/>} />,
          admin.admin(bundleProps),
          dynamic.autoAdmin(dynamicData),
          training.training(bundleProps),
          registration.register(bundleProps),
          account.resetPassword(bundleProps),
          // <Route path="/test" element={<Admin.ContentEditor />} />,
          <Route path="/presenter-pack" element={<Admin.PresenterPack />} />,
          <Route path="/presenter-pack/turners" element={<Web.PresenterPackBase user={{ ...props.user, userConfiguration: [{ type: 'data', name: 'web.presenter', data: { content: 'the.turners' }}]}} />} />,
          <Route path="/presenter-pack/sunrunners" element={<Web.PresenterPackBase user={{ ...props.user, userConfiguration: [{ type: 'data', name: 'web.presenter', data: { content: 'sun.runners' }}]}}/>} />,
          <Route path="/presenter-pack/bamboobandits" element={<Web.PresenterPackBase user={{ ...props.user, userConfiguration: [{ type: 'data', name: 'web.presenter', data: { content: 'bamboo.bandits' }}]}}/>} />,
          <Route path="*" element={<Page404/>} />,
        ]:[
          <Route path="/" element={<Admin.Login/>} />,
          account.resetPassword(bundleProps),
          training.training(bundleProps),
          registration.register(bundleProps),
          <Route path="/404" element={<Page404/>} />,
          <Route path="*" element={<Navigate to={'/'}/>} />,
        ]}
    </Routes>
  </BrowserRouter>;
}