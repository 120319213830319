import React, { Component } from 'react';
import { Button as BootButton, Spinner } from 'react-bootstrap';

export interface Props extends React.ComponentProps<typeof BootButton> {
  value: string,
  processing?: boolean,
  click?: () => void,
  variant?: string
}

export class BaseButton extends Component<Props, {}> {

  getStyle() {
    return this.props.style;
  }

  label(): string | JSX.Element {
    return this.props.value;
  }
  render() {
    return <div><BootButton {...this.props} style={this.getStyle()} onClick={() => this.props.click?this.props.click():undefined} variant={this.props.variant || "audioplay-primary"} >
      {this.props.processing === true?<Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>:this.label()}
    </BootButton></div>;
  }
}