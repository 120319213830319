import { useState, useRef } from 'react'; 
import { FestivalBinding, CampaignBinding, Context, Campaign as CampaignModel, Festival } from '@audioplay/client';
import { useNavigate } from 'react-router-dom';

import { FormBinding, useBinding } from './FormBinding';
import { Base as ModelBase, useItemCollection, useItemCollections } from './Base';
import { episodeInformation } from './Data';
import { Form, Control, Section, Row, FormController } from '../form';
import { useActivationFormGenerator } from './ActivationData';

export interface Props {
  item: CampaignModel,
  newItem: boolean,
  context: Context,
  closed: () => void
}

export function Campaign(props: Props) {
  const { loading, items } = useItemCollections({
    festivals: FestivalBinding.getBinding(props.context),
    campaigns: CampaignBinding.getBinding(props.context),
  });
  const activationBuilder = useActivationFormGenerator();
  const binding = useBinding(props.item);

  const template: (Control | Section | Row)[] = [];

  const states = useRef<{[key: string]: { active: boolean, handlers: {[key: string]:((active: boolean, controller: FormController) => void)} }}>({
    'access': {
      active: false,
      handlers: {},
    },
    'festival': {
      active: false,
      handlers: {},
    },
    'campaign': {
      active: false,
      handlers: {},
    }
  });

  if (!loading) {
    const campaignMap: { [key: string]: CampaignModel } = {};
    items.campaigns.forEach(i => campaignMap[i.id] = i);
    const festivalMap: { [key: string]: Festival } = {};
    items.festivals.forEach(i => festivalMap[i.id] = i);

    template.push({
      type: 'section',
      items: [{
        type: 'row',
        items:[
          binding.bind({
            type: 'text',
            title: 'Name',
          }, ['name']),
        ]
      }]
    });

    let a = props.item.activation;
    if (a === undefined || a === null) {
      props.item.activation = {};
      a = props.item.activation;
    }

    template.push(...activationBuilder.builder(a, campaignMap, festivalMap, binding, {
      access: states.current['access'].active,
      festivals: states.current['festival'].active,
      campaigns: states.current['campaign'].active,
      onToggle: (section, key, toggle) => {
        if (states.current[section] === undefined) {
          states.current[section] = {
            active: false,
            handlers: {},
          };
        }
        states.current[section].handlers[key] = toggle;
      },
      toggle: (section, controller) => {
        Object.entries(states.current).forEach(([k, v]) => {
          if (k === section) {
            v.active = !v.active;
          } else {
            v.active = false;
          }
          
          Object.values(v.handlers).forEach(h => h(v.active, controller));
        });
      }
    }, { access: true, campaigns: false, festivals: true }));
  }

  template.push({
    type:'label',
    value: props.newItem? 'NEW CAMPAIGN': (props.item.name || ''),
    display: {
      section: 'header',
      style: {
        fontSize: 'x-large',
        fontWeight: 600
      }
    }
  });
  template.push({
    type: 'row',
    display: {
      section: 'footer',
    },
    items: [
      {
        type: 'button',
        value: 'CLOSE',
        onClick: 'close',
        validationRequired: false,
        display: {
          props: {
            variant: "secondary"
          }
        }
      },
      {
        type: 'button',
        value: 'SAVE CHANGES',
        onClick: 'save',
      }
    ]
  });
  return <ModelBase
    close={() => { props.closed() }}
    save={() => Promise.resolve()}
    loading={loading}
    content={(header, footer) =>
      <Form
        key={'Campaign.Form'}
        headerElement={header}
        footerElement={footer}
        submitHandler={(action, data, controller) => {
          if (action === 'close') {
            props.closed();
          } else if (action === 'save') {
            const e = binding.resolve(data);
            if (e) {
              return Promise.resolve(e);
            }
            return props.item.save(props.context).then((r) => {
              if (r) {
                return activationBuilder.postSubmit()
                  .then((e) => {
                    if (e) {
                      return e;
                    }
                    props.closed();
                    return undefined;
                  });
              } else {
                return { 
                  'saveButton': 'Could not save successfully',
                }
              }
            }).catch(() => {
              return { 
                'saveButton': 'Could not save successfully',
              }
            })
          }
          return Promise.resolve(undefined);
        }} 
        template={[
          {
            type: 'section',
            items: template,
          }
        ]}
        templateLockId={props.item.id}
      />
    }
  />
}